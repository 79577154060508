import api_iac from '../../config/instance_axios';

export const rankings_service = {
    get_ranking,
    get_rankings
};

let user = JSON.parse(localStorage.getItem('user'));

async function get_ranking(){
    const token = user.token;
    const id = user.userid;

    const consulta = await api_iac.get(`webservice/rest/server.php?
        wstoken=${token}&
        wsfunction=local_targettpoints_get_user_points&
        moodlewsrestformat=json&
        userid=${id}
    `);

    const res = await consulta.data; 
    return res;
}

async function get_rankings(){
    const token = user.token;
    const id = user.userid;

    const consulta = await api_iac.get(`webservice/rest/server.php?
        wstoken=${token}&
        wsfunction=local_targettpoints_get_ranking&
        moodlewsrestformat=json&
        size=50
    `);

    const res = consulta.data; 
    return res;
}