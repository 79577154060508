import React, { useEffect, useState } from 'react';

// Enrutamiento
import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";


// Import Page
import ForgotPassword from '../Pages/Auth/ForgotPassword';
import Login from '../Pages/Auth/Login';
import Register from '../Pages/Auth/Register';
import Inicio from '../Pages/Dashboard/Inicio';
import Calificaciones from '../Pages/Dashboard/Calificaciones';
import Certificados from '../Pages/Dashboard/Certificados';
import Recompensas from '../Pages/Dashboard/Recompensas';
import Reconocimentos from '../Pages/Dashboard/Reconocimentos';
import VideoConferencias from '../Pages/Dashboard/VideoConferencias';
import MiPerfil from '../Pages/Dashboard/MiPerfil';
import Eventos from '../Pages/Dashboard/Eventos';
import Cursos from '../Pages/Dashboard/Cursos';
import Curso from '../Pages/Dashboard/Curso';


import { PrivateRoute } from '../Components/PrivateRoute/PrivateRoute';
import { history } from '../store/_helpers/history';
import Tema from '../Pages/Dashboard/Tema';
import TemaQuiz from '../Pages/Dashboard/TemaQuiz';
import Secuencia from '../Pages/Dashboard/Secuencia';
import Recurso from '../Pages/Dashboard/Recurso';
import Url from '../Pages/Dashboard/Url';
import Dctres from '../Pages/Dashboard/Dctres';
import Quiz from '../Pages/Dashboard/Quiz';
import Forum from '../Pages/Dashboard/Forum';
import Communication from '../Pages/Dashboard/Communication';




const RouterReact = () => {
    return (
        <Router basename="/" history={history}>
            <main style={{ minHeight: '50vh' }}>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    {/* <Route exact path="/registro" component={Register} />       
                    <Route exact path="/recuperar-contraseña" component={ForgotPassword} /> */}
                    <PrivateRoute exact path="/" component={Inicio} />
                    <PrivateRoute exact path="/inicio" component={Inicio} />
                    <PrivateRoute exact path="/cursos" component={Cursos} />
                    <PrivateRoute exact path="/curso/:id" component={Curso} />
                    <PrivateRoute exact path="/eventos" component={Eventos} />
                    <PrivateRoute exact path="/calificaciones" component={Calificaciones} />
                    <PrivateRoute exact path="/certificados" component={Certificados} />
                    <PrivateRoute exact path="/recompensas" component={Recompensas} />
                    <PrivateRoute exact path="/reconocimientos" component={Reconocimentos} />
                    <PrivateRoute exact path="/videoconferencias" component={VideoConferencias} />
                    <PrivateRoute exact path="/mi-perfil" component={MiPerfil} />

                    <PrivateRoute exact path="/tema/:instancia/:id" component={Tema} />
                    <PrivateRoute exact path="/temaquiz/:quizid" component={TemaQuiz} />
                    <PrivateRoute exact path="/secuencia/:scoid" component={Secuencia} />
                    <PrivateRoute exact path="/recurso/:courseid/:recursoid" component={Recurso} />
                    <PrivateRoute exact path="/url/:courseid/:urlid" component={Url} />
                    <PrivateRoute exact path="/dctres/:courseid/:dctresid" component={Dctres} />
                    <PrivateRoute exact path="/quiz/:quizid" component={Quiz} />
                    <PrivateRoute exact path="/forum/:forumid" component={Forum} />
                    <PrivateRoute exact path="/communication/:channelid" component={Communication} />


                    <Redirect from="*" to="/" />
                </Switch>
            </main>
        </Router>
    );
}

export default RouterReact;