import React from 'react';
import { useSelector } from 'react-redux';


const Reconocimientos = () => {
    const reconocimientos = useSelector(state => state.reconocimientos.reconocimientos);
    const token = useSelector(state => state.authentication.user.token);
    return ( 
        <>
            <div className="flex justify-center flex-col w-full">
                <h2 className="text-blue-500 font-black uppercase">Reconocimientos</h2>

                <div className="bg-blue-500 rounded-xl w-full p-1 mt-2">
                    <ul className="flex justify-center w-full">
                        { reconocimientos.length?reconocimientos.map(item => (
                            <li key={item.id} className="mx-2 flex text-white flex-col text-center"> 
                                <img style={{ width: '40px', height: '40px' }} src={`${item.badgeurl}?token=${token}`} alt="" draggable="false"/>
                                <p style={{ fontSize: '10px' }}>{item.name.substring(0,5)}</p>
                            </li>
                        )):<li class="text-white">No ha recibido reconocimientos aún</li> }
                    </ul>
                </div>
            </div>
        </>
    );
}
 
export default Reconocimientos;
