import { reconocimiento,reconocimientos_all } from '../_constants/reconocimientos';


const initialState = {
  reconocimientos: [],
};

export function reconocimientos_reducers(state = initialState, action) {
  switch (action.type) {
    case reconocimiento.RECONOCIMIENTOS_REQUEST:
      return {
        ...state,
        reconocimientos_loading: action.payload,
      };
    case reconocimiento.RECONOCIMIENTOS_SUCCESS:
      return {
        ...state,
        reconocimientos_loading: false,
        reconocimientos: action.payload
      };
    case reconocimiento.RECONOCIMIENTOS_FAILURE:
      return {
        ...state,
        reconocimientos_loading: false,
        error: action.payload
      };

      case reconocimientos_all.RECONOCIMIENTOS_ALL_REQUEST:
      return {
        ...state,
        reconocimientos_all_loading: action.payload,
      };
    case reconocimientos_all.RECONOCIMIENTOS_ALL_SUCCESS:
      return {
        ...state,
        reconocimientos_all_loading: false,
        reconocimientos_all: action.payload
      };
    case reconocimientos_all.RECONOCIMIENTOS_ALL_FAILURE:
      return {
        ...state,
        reconocimientos_all_loading: false,
        error: action.payload
      };
    default:
      return state
  }
}