import api_iac from '../../config/instance_axios';
import { config } from '../../config/user_token';

export const recompensas_service = {
    get_recompensas,
    get_canjeados,
    canjear_articulo
};

let user = JSON.parse(localStorage.getItem('user'));

async function get_recompensas() {
    const token = user.token;
    const id = user.userid;

    const consulta = await api_iac.post(`webservice/rest/server.php?
        wstoken=${token}&
        wsfunction=local_targettpoints_get_articles&
        moodlewsrestformat=json`
    );

    return consulta.data;
}

async function get_canjeados() {

    const token = user.token;
    const id = user.userid;

    const consulta = await api_iac.post(`webservice/rest/server.php?
        wstoken=${token}&
        wsfunction=local_targettpoints_get_exchanges&
        moodlewsrestformat=json&
        userid=${id}`
    );

    return consulta.data.exchanges;
}

async function canjear_articulo(articulo) {

    const token = user.token;
    const id = user.userid;

    const consulta = await api_iac.post(`webservice/rest/server.php?
        wstoken=${token}&
        wsfunction=local_targettpoints_exchange_article&
        moodlewsrestformat=json&
        userid=${id}&
        articleid=${articulo.id}`
    );

    if (Array.isArray(consulta.data.success === true)) {
        const articulos = consulta.data
        return { articulos };
    }

    const error = consulta.data;
    return { error };
}
