export const auth = (store) => (next) => (action) => {

    // const history = useHistory();
    if (action.type == "ALERT_ERROR") { console.error(JSON.stringify(action)); }
    //if(action.type == "ALERT_ERROR"){
        //localStorage.clear()
        //return window.location.reload();
        // return window.location.href = '/login';
    //}else{
        next(action);
    //}

};
