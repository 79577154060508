import { certificado, certificado_custom } from '../_constants/certificados';


const initialState = {
  certificados: [],
};

export function certificados_reducers(state = initialState, action) {
  switch (action.type) {
    case certificado.CERTIFICADOS_REQUEST:
      return {
        ...state,
        certificado_loading: action.payload,
      };
    case certificado.CERTIFICADOS_SUCCESS:
      return {
        ...state,
        certificado_loading: false,
        certificados: action.payload
      };
    case certificado.CERTIFICADOS_FAILURE:
      return {
        ...state,
        certificado_loading: false,
        error: action.payload
      };

    case certificado_custom.CUSTOM_CERTIFICADOS_REQUEST:
      return {
        ...state,
        loading: true,
        certificado_custom_loading: action.payload,
      };
    case certificado_custom.CUSTOM_CERTIFICADOS_SUCCESS:
      return {
        ...state,
        certificado_custom_loading: false,
        custom_certificados: action.payload
      };
    case certificado_custom.CUSTOM_CERTIFICADOS_FAILURE:
      return {
        ...state,
        certificado_custom_loading: false,
        error: action.payload
      };

    default:
      return state
  }
}