import moment from "moment";
import st from "./event.module.css";

const EventDetail = ({ event, handleClose }) => {
    console.log(event)
    return (
        <div className={st.event}>
            <div className={st.event__title}>
                <h5>{event.title}</h5>
            </div>
            <p>{event.notes}</p>
            <hr style={{ borderColor: event.bgcolor }} />
            <div>
                <div className={st.event__date_date}>
                    <span className={st.event_title}>Inicio</span>
                    <span>{moment(event.start).format("LLLL")}</span>
                    <span className={st.event_title}>Fin</span>
                    <span>{moment(event.end).format("LLLL")}</span>
                </div>
            </div>
            {event.linktipo && (
                <div className={st.event_btn}>
                    <a href={event.linktipo} target="_blank" rel="noreferrer">
                        Entrar
                    </a>
                </div>
            )}
        </div>
    );
};

export default EventDetail;
