import api_iac from '../../config/instance_axios';


export const meetings_service = {
    get_meetings
};

let user = JSON.parse(localStorage.getItem('user'));

async function get_meetings() {
    const token = user.token;
    const id = user.userid;

    const consulta = await api_iac.post(`webservice/rest/server.php?
        wstoken=${token}&
        wsfunction=targett_get_meeting_zoom&
        userid=${id}&
        moodlewsrestformat=json`
    );

    return consulta.data.meetings
}