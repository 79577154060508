export const ranking = {
    RANKING_REQUEST: 'RANKING_REQUEST',
    RANKING_SUCCESS: 'RANKING_SUCCESS',
    RANKING_FAILURE: 'RANKING_FAILURE',
}

export const rankings = {
    RANKINGS_ALL_REQUEST: 'RANKINGS_ALL_REQUEST',
    RANKINGS_ALL_SUCCESS: 'RANKINGS_ALL_SUCCESS',
    RANKINGS_ALL_FAILURE: 'RANKINGS_ALL_FAILURE',
}
