import { ranking,rankings } from '../_constants/ranking';


const initialState = {
  ranking: {},
  rankings: [],
};

export function ranking_reducers(state = initialState, action) {
  switch (action.type) {
    case ranking.RANKING_REQUEST:
      return {
        ...state,
        ranking_loading: action.payload,
      };
    case ranking.RANKING_SUCCESS:
      return {
        ...state,
        ranking_loading: false,
        ranking: action.payload
      };
    case ranking.RANKING_FAILURE:
      return {
        ...state,
        ranking_loading: false,
        ranking_error: action.payload
      };

      case rankings.RANKINGS_ALL_REQUEST:
      return {
        ...state,
        rankings_loading: action.payload,
      };
    case rankings.RANKINGS_ALL_SUCCESS:
      return {
        ...state,
        rankings_loading: false,
        rankings: action.payload
      };
    case rankings.RANKINGS_ALL_FAILURE:
      return {
        ...state,
        rankings_loading: false,
        rankings_error: action.payload
      };
    default:
      return state
  }
}