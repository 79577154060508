import { ranking,rankings } from '../_constants/ranking';
import { rankings_service } from '../_services/rankings';

import { alertActions } from './alert';

export const rankings_actions = {
    get_ranking,
    get_rankings
};

function get_ranking() {
    return dispatch => {
        dispatch( request() );

        rankings_service.get_ranking()
            .then(
                ranking => {
                    if(ranking != undefined && ranking != '' ){
                        dispatch(success(ranking));
                    }else{
                        dispatch(failure(ranking.error.toString()));
                        dispatch(alertActions.error(ranking.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: ranking.RANKING_REQUEST , payload: true } }
    function success(payload) { return { type: ranking.RANKING_SUCCESS , payload: payload } }
    function failure(error) { return { type: ranking.RANKING_FAILURE , payload: error } }
}

function get_rankings() {
    return dispatch => {
        dispatch( request() );

        rankings_service.get_rankings()
            .then(
                rankings => {
                    if(rankings.length > 0 && rankings != undefined && ranking != '' ){
                        dispatch(success(rankings));
                    }else{
                        dispatch(failure(rankings.error.toString()));
                        dispatch(alertActions.error(ranking.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: rankings.RANKINGS_ALL_REQUEST , payload: true } }
    function success(payload) { return { type: rankings.RANKINGS_ALL_SUCCESS , payload: payload } }
    function failure(error) { return { type: rankings.RANKINGS_ALL_FAILURE , payload: error } }
}