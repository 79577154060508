import React, { useEffect } from 'react';

// Enrutamiento
import { Link, NavLink } from "react-router-dom";

import inicio from '../../assets/img/iconos/desktop/bt_inicio_sf.png'
import certificados from '../../assets/img/iconos/desktop/bt_certificados_sf.png'
import eventos from '../../assets/img/iconos/desktop/bt_eventos_sf.png'
import cursos from '../../assets/img/iconos/desktop/bt_miscursos_sf.png'
import recompensas from '../../assets/img/iconos/desktop/bt_recompensas_sf.png'
import reconocimientos from '../../assets/img/iconos/desktop/bt_reconocimientos_sf.png'
import videoconferencias from '../../assets/img/iconos/desktop/bt_videoconferencias_sf.png'
import calificacion from '../../assets/img/iconos/desktop/bt_calificaciones_sf.png'

import MenuOpciones from './MenuOpciones';


const ItemsMenu = () => {
    return (
        <div className="menu-items hidden lg:flex lg:w-2/3 justify-end">
                <ul className="flex items-center">
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/inicio"> <img src={inicio} draggable="false"/></NavLink>
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/cursos"><img src={cursos} draggable="false"/></NavLink>                  
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/eventos"><img src={eventos} draggable="false"/></NavLink>
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/calificaciones"><img src={calificacion} draggable="false"/></NavLink>
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/certificados"><img src={certificados} draggable="false"/></NavLink>
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/recompensas"> <img src={recompensas} draggable="false"/></NavLink>
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/reconocimientos"><img src={reconocimientos} draggable="false"/></NavLink>
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/videoconferencias"><img src={videoconferencias} draggable="false"/></NavLink>
                    </li>
                    <li>
                        <MenuOpciones/>
                    </li>
                </ul>
            </div>
    );
}
 
export default ItemsMenu;
