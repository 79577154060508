import React,{useState} from 'react';
import Carousel, { CustomLeftArrow, CustomRightArrow } from 'react-multi-carousel';
import CardSlider from './CardSlider';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const CarouselList = ({ cursos, text, title }) => {
  /* const [fullScreen, set_fullScreen] = useState(window.screen.availWidth)
    window.addEventListener('resize', function(){
          let ancho = (window.screen.availWidth)
            set_fullScreen(ancho)
    }); 
console.log(cursos); */

  return (
    <div className="my-5 mx-1 w-95/1">
      <h2 className="text-blue-500 font-bold text-3xl uppercase w-full text-center">{title}</h2>

        <Carousel
          additionalTransfrom={0}
          arrows={true}
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="carousel-container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          // customLeftArrow={<CustomLeftArrow />}
          // customRightArrow={<CustomRightArrow />}
          responsive={responsive}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {cursos === undefined ? (
            <p className="uppercase text-8xl text-black">Cargando</p>
          ) : (
            cursos.map((card, i) => {
              card.time = (i + 1) * 1;
              return (
                <div className="" key={i}>
                  <CardSlider card={card} text={text} />
                </div>
              );
            })
          )}
        </Carousel>
    </div>
  )
}

export default CarouselList
