import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import inicio from '../../assets/img/iconos/mobile/inicio.png'
import certificados from '../../assets/img/iconos/mobile/certificados.png'
import eventos from '../../assets/img/iconos/mobile/eventos.png'
import cursos from '../../assets/img/iconos/mobile/cursos.png'
import recompensas from '../../assets/img/iconos/mobile/recompensas.png'
import reconocimientos from '../../assets/img/iconos/mobile/reconocimientos.png'
import videoconferencias from '../../assets/img/iconos/mobile/video.png'
import calificacion from '../../assets/img/iconos/mobile/calificacion.png'

const MenuHamburguesa = () => {
    const node = useRef();

    const [open, setOpen] = useState(false)

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => document.removeEventListener("mousedown", handleClick);

    }, []);

    const handleClick = e => {
        if (node.current != undefined) {
            if(node.current.contains(e.target) == false ){
                setOpen(false)
            }
        }
    };

    return (
        <div  onClick={() => setOpen(!open) }  className="content_menu_hamburguesa">
            <div className="menu_hamburguesa flex flex-col items-center justify-center">
                <div className="bg-white mb-2" style={{ width: '35px', height:'4px' }}></div>
                <div className="bg-white mb-2" style={{ width: '35px', height:'4px' }}></div>
                <div className="bg-white" style={{ width: '35px', height:'4px' }}></div>
            </div>

            { open ? 
            <div ref={node} onClick={handleClick} className="menu_hamburguesa_items p-1 bg-blue-500">
                <ul>
                    <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/inicio"> <img style={{ width:'150px', maxWidth: 'unset' }} src={inicio} alt="" draggable="false"/> </NavLink>  </li>
                    <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/cursos"> <img style={{ width:'150px', maxWidth: 'unset' }} src={cursos} alt="" draggable="false"/> </NavLink>  </li>
                    <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/eventos"> <img style={{ width:'150px', maxWidth: 'unset' }} src={eventos} alt="" draggable="false"/> </NavLink>  </li>
                    <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/calificaciones"> <img style={{ width:'150px', maxWidth: 'unset' }} src={calificacion} alt="" draggable="false"/> </NavLink>  </li>
                    <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/certificados"> <img style={{ width:'150px', maxWidth: 'unset' }} src={certificados} alt="" draggable="false"/> </NavLink>  </li>
                        <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/recompensas"> <img style={{ width: '150px', maxWidth: 'unset' }} src={recompensas} alt="" draggable="false" /> </NavLink>  </li>
                        <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/reconocimientos"> <img style={{ width: '150px', maxWidth: 'unset' }} src={reconocimientos} alt="" draggable="false" /> </NavLink>  </li>
                    <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/videoconferencias"> <img style={{ width:'150px', maxWidth: 'unset' }} src={videoconferencias} alt="" draggable="false"/> </NavLink>  </li>
                </ul>
            </div>
            : null } 
        </div> 
    );
}
 
export default MenuHamburguesa;