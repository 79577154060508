import React from 'react';
import logo from './logo.svg';
import 'react-multi-carousel/lib/styles.css';
// Archivo de rutas
import RouterReact from './Route/RouterReact';


function App(props) {
  return (
    <div className="App">
       <RouterReact></RouterReact>
    </div>
  );
}

export default App;
