import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Recompensa from '../../Components/Recompensas/Recompensa';
import Tag from '../../Components/Tag';
import { recompensas_actions } from '../../store/_actions/recompensas';
import { rankings_actions } from '../../store/_actions/ranking';
import {ReactComponent  as Loading } from "../../assets/loading.svg";


const Recompensas = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(recompensas_actions.get_recompensas());
        dispatch(recompensas_actions.get_canjeados());
        dispatch(rankings_actions.get_ranking());
    }, []);

    const recompensas_action = useSelector(state => state.recompensas);
    const canjeados = [];
    recompensas_action.canjeados.map(item => {
        canjeados.push(item);
    });

    return (
        <>
            {recompensas_action.recompensas_loading === true ? 
                <div style={{ height: '50vh' }} className='flex items-center justify-center items-center'>
                    <Loading className='animate-spin h-16 w-16 inline-block text-blue-500'/>
                </div>
                :
                <>
                    <div className="flex justify-center items-center md:items-center lg:items-end lg:flex-row md:justify-center lg:justify-end">
                        <Tag imagen="recompensa.png" type="RECOMPENSAS OBTENIDAS" number={recompensas_action.canjeados.length} />
                    </div>

                    <div className="container mx-auto flex justify-center items-center lg:items-start lg:justify-start">
                        <div className="p-4 text-center lg:text-left">
                            <h3 className="text-2xl uppercase text-blue-500 font-black mb-5">
                                Mis Recompensas
                            </h3>
                            {/* <select style={{ border: '1px solid #666' }} defaultValue={'DEFAULT'}>
                                <option disabled value="DEFAULT">Todas las recompensas</option>
                                <option value="1">Recompensas obtenidas</option>
                                <option value="2">Recompensas desbloqueadas</option>
                                <option value="3">Recompensas bloqueadas</option>
                            </select> */}
                        </div>
                    </div>

                    <div className="container mx-auto flex flex-wrap w-full items-baseline text-center mt-5 mb-5">
                        {recompensas_action.recompensas_loading == false ?
                            recompensas_action.recompensas.length > 0 ?
                                recompensas_action.recompensas.map(item => <Recompensa itemRecompensas={item} itemCanjeados={canjeados} />)
                                : 'Sin recompensas'
                            : 'Cargando...'}
                    </div>

                    {/* <Footer/> */}
                </>
            }
        </>
    );
}

export default Recompensas;