import React from 'react';


const Tag = ({ imagen, type, number }) => {

    const imgDafi = require(`../assets/img/${imagen}`)
    const imgJubi = require(`../assets/img-jubicom/${imagen}`)


    let theme = localStorage.getItem('theme');

    return (
        <>
            <div className="w-3/4 sm:w-1/2 lg:w-1/3">
                <div className={`flex ${theme === 'jubicom' ? "bgJubicom" : "bgDafi"} flex-wrap bg-blue-400 rounded-b-3xl px-1 pt-8 pb-4 w-full lg:w-3/4 items-center justify-center m-auto`}>
                    <div className="md:mr-5" style={{ maxWidth: '90px', width: '100%' }}>
                        <img className="w-full" src={theme === 'jubicom' ? imgJubi.default : imgDafi.default} alt="" draggable="false"/>
                    </div>
                    <div className="text-center">
                        {imagen === 'recompensa.png' ? <p className="text-white text-sm">{type}</p> : <p className="text-white text-xl">{type}</p>}
                        <p className="text-white text-4xl">{number}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tag;