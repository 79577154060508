import React from 'react';

import MenuHamburguesa from './MenuHamburguesa';
import MenuOpciones from '../Nav/MenuOpciones';

import BuscadorMobile from './BuscadorMobile';

const MenuMobile = () => {
    return ( 
        <div className="flex items-center justify-center lg:hidden">
            <BuscadorMobile/>
            <MenuOpciones/>
            <MenuHamburguesa/>
        </div>
    );
}
 
export default MenuMobile;