import React, { useState, useEffect } from "react";
import Calificacion from "../../Components/Calificaciones/Calificacion";
import Tag from "../../Components/Tag";
import { useDispatch, useSelector } from "react-redux";
import { cursos_actions } from "../../store/_actions/cursos";
import {ReactComponent  as Loading } from "../../assets/loading.svg";
import { rankings_actions } from '../../store/_actions/ranking';

const Calificaciones = () => {
    const dispatch = useDispatch();

    const [currentScore, setCurrentScore] = useState(null);

    useEffect(() => {
        dispatch(rankings_actions.get_ranking());
        const get_cursos_inscritos = () =>
            dispatch(cursos_actions.getCursosInscritos());
        get_cursos_inscritos();
    }, []);

    const cursos = useSelector((state) => state.cursos.cursos);
    const loading = useSelector(state => state.cursos);

    const calificaciones = cursos.filter((item) => item.progress > 0);

    let total = 0;

    calificaciones.forEach((item) => {
        total += item.grade == "-" ? 0 : Number.parseFloat(item.grade);
    });

    const promedio = Number(total / calificaciones.length).toFixed(1);

    return (
        <>
            {
                loading.loading === true ? 
                    <div style={{ height: '50vh' }} className='flex items-center justify-center items-center'>
                        <Loading className='animate-spin h-16 w-16 inline-block text-blue-500'/>
                    </div>
                :
                <div style={{ height: calificaciones.length > 1 ? "auto" : '50vh' }}>
                    <div className="flex justify-center items-center md:items-center lg:items-end lg:flex-row md:justify-center lg:justify-end">
                        <Tag imagen="trofeo.png" type="Promedio" number={promedio === "NaN" ? "0" : promedio} />
                    </div>
                    <div className="container mx-auto flex justify-center items-center lg:items-start lg:justify-start">
                        <div className="p-4 text-center lg:text-left">
                            <h3 className="text-2xl uppercase text-blue-500 font-black mb-5">
                                Mis Calificaciones
                            </h3>
                        </div>
                    </div>
                    <div className="container mx-auto flex flex-wrap w-full mt-5 mb-5">
                        <div className="px-8 py-14 lg:p-8 w-full lg:w-3/4 bg-white relative">
                            {
                                calificaciones.length > 0 ? 
                                    <div className="flex justify-between items-center bg-white pb-5">
                                        <div className="w-1/2 md:w-3/4"></div>
                                        <div className="w-2/12 md:w-2/5 flex justify-center">
                                            <p className="text-gray-700">Avance</p>
                                        </div>
                                        <div className="w-3/12 md:w-3/12 flex justify-center -mr-2 lg:mr-0">
                                            <p className="text-gray-700">Calificación</p>
                                        </div>
                                    </div>
                                : null
                            }

                            <div className="bg-white">
                                {
                                    calificaciones.length > 0 ? 
                                        calificaciones.map((curso) => (
                                            <Calificacion
                                                key={curso.id}
                                                curso={curso}
                                                currentScore={currentScore}
                                                setCurrentScore={setCurrentScore}
                                            />
                                        ))
                                    : "No has temininados ningun curso actualmente"
                                }
                            </div>
                        </div>

                    </div>

                    {/* <Footer/> */}
                </div>
            }
        </>
    );
};

export default Calificaciones;
