export const reconocimiento = {
    RECONOCIMIENTOS_REQUEST: 'RECONOCIMIENTOS_REQUEST',
    RECONOCIMIENTOS_SUCCESS: 'RECONOCIMIENTOS_SUCCESS',
    RECONOCIMIENTOS_FAILURE: 'RECONOCIMIENTOS_FAILURE',
}

export const reconocimientos_all = {
    RECONOCIMIENTOS_ALL_REQUEST: 'RECONOCIMIENTOS_ALL_REQUEST',
    RECONOCIMIENTOS_ALL_SUCCESS: 'RECONOCIMIENTOS_ALL_SUCCESS',
    RECONOCIMIENTOS_ALL_FAILURE: 'RECONOCIMIENTOS_ALL_FAILURE',
}
