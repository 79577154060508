import React from 'react';


const Reconocimiento = ({ url_img, name,text, lock, id }) => {
    const style = {
        filter: "grayscale(100%)"
    }
    return (
        <div className={`flex flex-wrap justify-center items-center text-center w-1/2 md:w-1/4 lg:w-1/4 }`} style = {  lock ? null : style }>

            <div className="mt-5">
                <div style={{ width: '90px', height:'90px', margin: 'auto' }}> 
                    <img className='mx-auto' style={{ width: '90px', height:'90px' }} src={url_img} alt="" draggable="false"/>
                </div>
                <div style={{height:'90px', margin: 'auto' }}>
                    <p className="font-black uppercase">{name}</p>
                    <p>{ text }</p>
                </div>
            </div>
        </div>
    );
}
export default Reconocimiento;