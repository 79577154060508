import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import api_iac from '../../config/instance_axios';
import ordinal from 'ordinales';


const TemaQuiz = (props) => {
    const history = useHistory();
    const { quizid } = props.match.params;
    const user = useSelector(state => state.authentication.user);

    const [dataQuiz, setDataQuiz] = useState([]);
    const [dataQuiz2, setDataQuiz2] = useState([]);
    const [maxValue, setMaxValue] = useState([]);



    const detalles = async () => {
        const request_detalles = await api_iac.get(`webservice/rest/server.php?
            wstoken=${user.token}&
            wsfunction=targett_get_exam_data&
            &moodlewsrestformat=json&
            id=${quizid}`
        )

        const res = await request_detalles.data;
        //console.log(res)

        if (res.prevattemptslist !== undefined) {
            setDataQuiz2(res.prevattemptslist);
        }
        else {
            setDataQuiz2([]);
        }

        setDataQuiz(res);

        let max = maxValueAttempt(res.prevattemptslist);
        setMaxValue(max);

    }

    useEffect(() => {
        detalles();
    }, []);

    const redireccionar = () => {
        history.push(`/quiz/${quizid}`)
    }

    const maxValueAttempt = (arr) => {
        console.log(arr);
        var maxValue = Number.MIN_VALUE;

        if (arr !== undefined) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].sumgrades > maxValue) {
                    maxValue = arr[i].sumgrades;
                }
            }
            return maxValue;
        }
        else {
            return 0;
        }

    }

    return (
        <div className="bg-white p-4 text-center">
            <div className="mb-4 text-left"> <h3 className="text-4xl font-black text-blue-500">{dataQuiz.name}</h3> </div>

            <div className="text-gray-800 mb-10 text-left">
                <p>{dataQuiz.description}</p>
            </div>

            <hr style={{ color: '#a9a9a9', backgroundColor: '#a9a9a9', height: 1, borderColor: '#a9a9a9', }} />

            <div className="my-10">
                <p className="">Numero de intentos permitidos: {dataQuiz.allowedattempts}</p>
                <p className="text-blue-500">Intentos disponibles: {dataQuiz.availableattempts}</p>
            </div>

            <hr style={{ color: '#a9a9a9', backgroundColor: '#a9a9a9', height: 1, borderColor: '#a9a9a9', }} />

            {
                dataQuiz2.length !== 0 ?
                    <>
                        <table className='mx-auto mt-10 max-w-5xl w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 overflow-hidden'>
                            <thead className="bg-blue-500">
                                <tr className="text-white text-left">
                                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Intento </th>
                                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Estado </th>
                                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Calificación / 100 </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {
                                    dataQuiz2.map((attempt, i) => (
                                        <tr key={i} className="bg-gray-100">
                                            <td className="px-6 py-4 text-center">
                                                <p className=""> {ordinal(attempt.attemptnum, "male")} </p>
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                <p className=""> {attempt.state === 'inprogress' ? 'En progreso' : 'Finalizado'} </p>
                                            </td>
                                            <td className="px-6 py-4 text-center">
                                                <p className=""> {attempt.sumgrades === null ? 0 : attempt.sumgrades} </p>
                                            </td>
                                        </tr>
                                    ))
                                }
                                <p className="text-lg mt-5 text-left">Calificación mas alta: {maxValue === 5e-324 ? 0 : maxValue} / 100 </p>
                            </tbody>
                        </table>
                    </>
                    : ''
            }

            {
                dataQuiz.availableattempts === 0 ?
                    <div>
                        <h3 className="text-2xl font-black text-blue-500 mt-10">Ha alcanzado el número máximo de intentos</h3>
                        {/* <p className="text-lg mt-5 text-left">Calificación mas alta: {maxValue === 5e-324 ? 0 : maxValue} / 100 </p> */}
                    </div>

                    :
                    <button className="bg-blue-500 rounded-2xl px-8 py-2 mt-10 text-white"> <p onClick={() => redireccionar()}>Entrar</p> </button>
            }

            <div className="mb-16"></div>
        </div>
    );
}

export default TemaQuiz;