import React from 'react';

// Assets
import logo from '../assets/img/logo_auth.png';
import dafi from '../assets/img/dafi.png';


const FormAuth = (props) => {
    return ( 
        <div className="flex justify-center min-h-screen max-w-screen-xl m-auto">
            <div className="flex justify-center flex-col items-center dafi-fondo-auth">
                <div className="bg-white py-12 px-8 w-72 sm:w-80 md:w-96 lg:w-96 xl:w-96 shadow-xl rounded-xl mb-4">
                    <div>
                        <div className="flex justify-center mb-4"> <img src={logo} alt="logo" draggable="false"/> </div>

                        <div>
                            {props.children}
                        </div>    
                    </div>
                </div>
                <div>
                    <img src={dafi} alt="dafi" draggable="false"/>
                </div>
            </div>
        </div>
    );
}
 
export default FormAuth;