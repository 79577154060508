import React, { useState } from 'react';

import logo from '../../assets/img/logo.png'
import logoJubicom from '../../assets/img-jubicom/logo.png'

import Buscador from './Buscador';
import ItemsMenu from './ItemsMenu';

import MenuMobile from '../MenuMobile/MenuMobile';
import { Link } from 'react-router-dom';



const Nav = () => {
    let theme = localStorage.getItem('theme');
    const [fullScreen, set_fullScreen] = useState(window.screen.availWidth > 800 ? '100%': window.screen.availWidth )
    window.addEventListener('resize', function(){
        if(window.screen.availWidth > 800 ){
            set_fullScreen('100%')
        
        }else{
            set_fullScreen(window.screen.availWidth)
        }
    }); 
    return (
        <>
            <nav className="bg-blue-500 text-white flex  justify-between items-center p-4 fixed z-1000 top-0 h-24" style={{width:fullScreen}}>
                <div className="flex w-1/3 items-center justify-start">
                    <div className="mr-2" style={{ width: '100px' }}> <Link to="/inicio"><img style={{ width: '100px'}} src={theme === 'jubicom' ? logoJubicom : logo} alt="" draggable="false"/></Link> </div>
                    <div className="border mr-2 h-12"></div>
                    <Buscador />
                </div>
                <ItemsMenu />
                <MenuMobile />
            </nav>
        </>

    );
}

export default Nav;
