import { user_login, user_logout } from '../_constants/user_auth';
import { userService } from '../_services/user';
import { alertActions } from './alert';
import { history } from '../_helpers/history';
import Swal from 'sweetalert2';


export const userAuthActions = {
    login,
    logout
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                res => {
                    
                    if (res.user != undefined) {
                        res.user.password = password;
                        dispatch(success(res.user));
                        history.push('#/inicio');
                        window.location.reload();
                    }
                    else if (res.tutor === true) {
                        Swal.fire({
                            title: "¡Las credeciales no son validas pare el login de usuario!",
                            text: "redirigiendo al login correcto.",
                            icon: "error",
                            confirmButtonText: "Aceptar",
                        }).then(function () {
                            window.location = "https://administrador.universidaddafi.com.mx/#/login";
                        });
                    }
                    else {
                        dispatch(failure(res.error.toString()));
                        dispatch(alertActions.error(res.error.toString()));
                    }

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: user_login.LOGIN_REQUEST, payload: true } }
    function success(user) { return { type: user_login.LOGIN_SUCCESS, payload: user } }
    function failure(error) { return { type: user_login.LOGIN_FAILURE, payload: error } }
}

function logout() {
    userService.logout();
    window.location.reload();
    return { type: user_logout.LOGOUT };
}
