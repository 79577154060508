import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Aside from '../../Components/Aside/Aside';
import Calendario from '../../Components/Calendario/Calendario';
import MinCalendar from '../../Components/Calendario/MinCalendar';
// import Ranking from '../../Components/Ranking/Ranking';
// import ListSlider from '../../Components/Slider/ListSlider';
// import CarouselList from "../../Components/Slider/CarouselList";
import { cursos_actions } from '../../store/_actions/cursos';
import { rankings_actions } from '../../store/_actions/ranking';
import { reconocimientos_actions } from '../../store/_actions/reconocimientos';


const Eventos = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const get_cursos_inscritos = () => dispatch(cursos_actions.getCursosInscritos());
        const getReconocimientos = () => dispatch(reconocimientos_actions.getreconocimientos());
        const get_ranking = () => dispatch(rankings_actions.get_ranking());
        const get_rankings = () => dispatch(rankings_actions.get_rankings());
        get_ranking();
        get_rankings();
        getReconocimientos();
        get_cursos_inscritos();
    }, []);

    // const cursos_disponibles = useSelector(state => state.cursos.cursos);
    // const cursos = cursos_disponibles.filter(item => item.progress == 0);

    let theme = localStorage.getItem('theme');

    return (
        <>
            <div className="flex flex-col flex-wrap sm:flex-row">
                <Aside />

                <div className={`flex ${theme === 'jubicom' ? "bgMainJubicom" : "bgMainDafi"} flex-wrap flex-col w-full md:w-3/4 lg:w-4/5 lg:flex-nowrap`}>
                    <div className="flex flex-wrap justify-center w-full lg:flex-nowrap">
                        <div className="m-5 w-3/4">
                            {/* <Calendario canShow={true} /> */}
                            <MinCalendar canShow={true} />
                        </div>
                        {/* <div className="m-5 w-full md:w-2/3 lg:w-3/5">
                            <Ranking />
                        </div> */}
                    </div>

                    {/* {cursos.length > 0 ?
                        <div className="w-full">
                            <CarouselList title="Mis cursos disponibles" cursos={cursos} text="iniciar" />
                        </div>
                        : null} */}
                </div>
            </div>
        </>
    );
}

export default Eventos;