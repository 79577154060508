import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const Buscador = () => {
    const [ busqueda, guardarBusqueda ] = useState('');
    const [ busquedaRes, guardarBusquedaRes ] = useState([]);
    const [ busquedaShow, guardarBusquedaShow ] = useState(false);

    // const inputRef = useRef();
    const obtenerCursos = useSelector(state => state.cursos.cursos);
    const history = useHistory();

    const redireccionar = (id) => {
        guardarBusquedaShow(true)
        history.push(`/curso/${id}`)
        window.location.reload();
    };

    const handleChange = e => {
        guardarBusqueda(e)
        if(e != '' && e != undefined){
            const resultado = obtenerCursos.filter( item => {
                if(item){
                    return item.fullname.toLowerCase().search(e.toLowerCase()) !== -1;
                }
            });
            guardarBusquedaRes(resultado);
        }else{
            guardarBusquedaRes([])
        }
    }
    const handleFocus = (e)=>{
        console.log(e)
        guardarBusquedaShow(true) 
    }

    const handleBlur = (e)=>{
        console.log(e);
        if (e.currentTarget === e.target) {
            console.log('unfocused self');
          }
        guardarBusquedaShow(false) 
    }
    return ( 
        <div>
            <div className="relative" onSelect={ (e) => handleFocus(e) }>
                <div className="buscador hidden lg:block" >
                    <input  onChange={(e)=>{handleChange(e.target.value)}} name="busqueda" className="bg-blue-100 p-1 rounded" type="text" placeholder="Escribe tu búsqueda"/>
                    <i className="fas fa-search"></i>
                </div>
                <>
                {  busquedaRes.length > 0 && busquedaShow === true ?
                    <div className="absolute bg-white top-0 w-full z-50 shadow-sm" style={{ transform: 'translateY(30px)', maxWidth: '198px' }}>
                    { busquedaRes.map(item => (
                        <div onClick={ () => redireccionar(item.id) } className="p-1 text-black cursor-pointer hover:bg-gray-400" > 
                            <p className="text-sm" >{ item.fullname }</p> 
                        </div>
                    )) }
                    </div>
                : null }
                </>
            </div>
        </div>
    );
}
 
export default Buscador;