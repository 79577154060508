import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import { config } from '../../config/user_token';

import cardTopImage from '../../assets/img/card-top.jpg';

let user = JSON.parse(localStorage.getItem('user'));

const CardSlider = ({ card, text }) => {
  const history = useHistory();

  const redireccionar = (id) => history.push(`curso/${id}`);
  const token = user.token;

  const [classNameUp, setClassNameUp] = useState("v-hidden");
  let background_image = '';

  const calcalto = () => {
    let cards = document.querySelectorAll(".summaryCards");
    let alto = 0;
    Array.from(cards).map((item, index) => {
      //console.log(item.clientHeight);
      alto = item.clientHeight > alto ? item.clientHeight : alto;
    });

    for (let i = 0; i < cards.length; i++) {
      cards[i].style.height = alto + "px";
    }
    
    //console.log("alto", alto);
  }
  useEffect(() => {
    calcalto();
    const timer = setTimeout(() => {
      setClassNameUp("effect visibility-v");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  if (card.overviewfiles[0]?.fileurl) {
    background_image = `${card.overviewfiles[0].fileurl}?token=${token}`;
  } else {
    background_image = cardTopImage;
  }

  return (
    <div className={`cardSlider rounded-3xl overflow-hidden shadow-2xl my-5 mx-2 max-h-auto ${classNameUp}`}
      style={{ transition: `right ${card.time}s, transform ${card.time}s` }}>
      
      <div className="w-full h-48 bg-cover bg-center bg-no-repeat" style={{backgroundImage: `url(${background_image})`}}>&nbsp;</div>
      

      <div className="px-6 py-4 h-36 overflow-hidden summaryCards h-auto">
        <div
          className="font-bold text-lg mb-2 text-blue-500"
          style={{ wordWrap: "break-word" }}
        >
          {card.fullname}
        </div>

        <div className="overflow-hidden">
          <p
            className="text-grey-darker text-sm overflow-hidden leading-4"
            dangerouslySetInnerHTML={{ __html: card.summary }}
          />
        </div>
      </div>

      <div className="px-6 py-4 flex justify-center">
        <button
          onClick={() => redireccionar(card.id)}
          className="inline-block px-3 py-1 text-12 font-medium leading-6 text-center text-white uppercase transition bg-blue-500 rounded-full shadow ripple hover:shadow-lg hover:bg-blue-800 focus:outline-none"
        >
          {text}
        </button>
      </div>

    </div>
  );
};

export default CardSlider;
