import React from 'react';


const ActividadesRecientes = ({ cursos }) => {
    console.log(cursos);
    return (
        <div className="w-full md:w-1/2 lg:w-1/3 mb-5 lg:pl-6 lg:pr-6">
            <div className="text-blue-500 font-black text-2xl mb-2">Cursos Inscritos</div>
            <div>
                <div className="mb-3">
                    {
                        cursos.map(course => {
                            return (
                                <p key={course.id} className="text-gray-800">{course.fullname}</p>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default ActividadesRecientes;