import { recompensas, canjeados } from '../_constants/recompensas';
import { recompensas_service } from '../_services/recompensas';
import { alertActions } from './alert';
import Swal from 'sweetalert2'

export const recompensas_actions = {
    get_recompensas,
    get_canjeados,
    canjear_articulo
};

function get_recompensas() {
    return dispatch => {
        dispatch(request());

        recompensas_service.get_recompensas()
            .then(
                recompensas => {
                    if (recompensas.length > 0) {
                        dispatch(success(recompensas));
                    } else {
                        dispatch(failure(recompensas.error.toString()));
                        dispatch(alertActions.error(recompensas.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: recompensas.RECOMPENSAS_REQUEST, payload: true } }
    function success(param) { return { type: recompensas.RECOMPENSAS_SUCCESS, payload: param } }
    function failure(error) { return { type: recompensas.RECOMPENSAS_FAILURE, payload: error } }
}

function get_canjeados() {
    return dispatch => {
        dispatch(request());

        recompensas_service.get_canjeados()
            .then(
                canjeados => {
                    if (canjeados.length > 0) {
                        dispatch(success(canjeados));
                    } else {
                        dispatch(failure(canjeados.error.toString()));
                        dispatch(alertActions.error(canjeados.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: canjeados.RECOMPENSAS_GET_EXCHANGE_REQUEST, payload: true } }
    function success(param) { return { type: canjeados.RECOMPENSAS_GET_EXCHANGE_SUCCESS, payload: param } }
    function failure(error) { return { type: canjeados.RECOMPENSAS_GET_EXCHANGE_FAILURE, payload: error } }
}

async function canjear_articulo(articulo) {
    await recompensas_service.canjear_articulo(articulo)
        .then(
            res => {
                if (res.hasOwnProperty('success')) {
                    failure(res.error.message.toString());
                    return Swal.fire(
                        'Articulo',
                        `${res.message}`,
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        {
                            title: 'Canjeado con exito',
                            icon: 'success',
                            html:
                                '<p style="color: #154165; font-weight: 400;"><span style="font-weight: 800;">Nota:</span> Acércate a tu asistente regional para hacer el canje.</p>',
                        }
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: recompensas.RECOMPENSAS_EXCHANGE_REQUEST, payload: true } }
    function success(param) { return { type: recompensas.RECOMPENSAS_EXCHANGE_SUCCESS, payload: param } }
    function failure(error) { return { type: recompensas.RECOMPENSAS_EXCHANGE_FAILURE, payload: error } }
}