import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { cursos_actions } from "../../store/_actions/cursos";

import DetallesUsuario from '../../Components/MiPerfil/DetallesUsuario';
import Privacidad from '../../Components/MiPerfil/Privacidad';
import CursosInscritos from '../../Components/MiPerfil/CursosInscritos';

import Reportes from '../../Components/MiPerfil/Reportes';
import AppMobile from '../../Components/MiPerfil/AppMobile';


const MiPerfil = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const get_cursos_inscritos = () => dispatch(cursos_actions.getCursosInscritos());
        get_cursos_inscritos();
    }, []);

    const cursos = useSelector(state => state.cursos.cursos);

    /* const reportes = [
        'Sesiones del navegador',
        'Vista general de calificaciones',
    ] */
    return (
        <>
            <div className="container mx-auto mt-5 mb-5 p-6 lg:p-8">
                <div className="flex flex-wrap break-all">
                    <DetallesUsuario />
                    <CursosInscritos cursos={cursos} />
                    <Privacidad />
                </div>
                {/* <div className="w-full lg:w-1/3">
                            <ActividadesRecientes/>
                            <Reportes reportes={reportes} />                     
                        </div> */}
            </div>
        </>
    );
}

export default MiPerfil;