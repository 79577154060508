import { recompensas, canjeados } from '../_constants/recompensas';


const initialState = {
  recompensas: [],
  canjeados: []
};

export function recompensas_reducers(state = initialState, action) {
  switch (action.type) {
    case recompensas.RECOMPENSAS_REQUEST:
      return {
        ...state,
        recompensas_loading: action.payload,
      };
    case recompensas.RECOMPENSAS_SUCCESS:
      return {
        ...state,
        recompensas_loading: false,
        recompensas: action.payload
      };
    case recompensas.RECOMPENSAS_FAILURE:
      return {
        ...state,
        recompensas_loading: false,
        error: action.payload
      };


      case canjeados.RECOMPENSAS_GET_EXCHANGE_REQUEST:
      return {
        ...state,
        canjeados_loading: action.payload,
      };
    case canjeados.RECOMPENSAS_GET_EXCHANGE_SUCCESS:
      return {
        ...state,
        canjeados_loading: false,
        canjeados: action.payload
      };
    case canjeados.RECOMPENSAS_GET_EXCHANGE_FAILURE:
      return {
        ...state,
        canjeados_loading: false,
        error: action.payload
      };
    default:
      return state
  }
}