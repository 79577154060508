import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api_iac from '../../config/instance_axios';

const Url = (props) => {
    const { courseid, urlid } = props.match.params;
    const [url, setUrl] = useState('')

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        getIframe(courseid, user.token, urlid)
    }, [])

    const getIframe = async (idcourse, token, idurl) => {

        const consulta = await api_iac.post(`webservice/rest/server.php?
            wstoken=${token}&
            wsfunction=mod_url_get_urls_by_courses&
            moodlewsrestformat=json&
            courseids[0]=${idcourse}`
        ).then(function (response) {
            const urlItems = response.data.urls;
            const detailsUrl = urlItems.filter(url => url.id == idurl);
            const urlU = detailsUrl[0].externalurl;

            setUrl(urlU);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleClick = async (token, urlid) => {
        //console.log("Se hizo click");
        const viewed = await api_iac.post(`webservice/rest/server.php?
            wstoken=${token}&
            wsfunction=mod_url_view_url&
            moodlewsrestformat=json&
            urlid=${urlid}`
        );

        //console.log(viewed);
    };

    const user = useSelector(state => state.authentication.user);

    return (
        <div className="flex items-center justify-center mx-auto customContainerUrl bg-white">
            <p>Haga clic en el enlace <a className="text-blue-500" onClick={() => handleClick(user.token, urlid)} href={url} target="_blank" rel="noreferrer">{url}</a> para abrir la URL.</p>
        </div>
    );
}

export default Url;