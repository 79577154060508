import React, { useEffect, useState } from 'react';

import logo from '../assets/img/logo.png'
import dafi from '../assets/img/dafi_white.png'
import logoJubicom from '../assets/img-jubicom/logo.png'
import regresar from '../assets/img/regresar.png'


import { useHistory } from "react-router-dom";

const Footer = ({props}) => {
    let theme = localStorage.getItem('theme');

    let history = useHistory();
    const [back, setBack] = useState(false);
    let url = props.match.url.split("/")[1];
    let { pathname } = window.location;
    console.log(url);
    useEffect(() => {
        if (pathname == '/inicio' || pathname == '/') { 
            setBack(false)
        }else{
            setBack(true)
        }
    },[]);

    function handleClick() {
        history.goBack();
    }

    return (
        
        <> 
            { back ?  
                <div className="flex justify-end my-4 fixed bottom-0 right-0 lg:static cursor-pointer">
                    <div className="flex items-center text-blue-500 text-2xl font-black uppercase" onClick={handleClick}>
                    <p className="hidden lg:block">Regresar</p>
                    <img src={regresar} alt=""/>
                    </div> 
                </div>
            : <></> }
            {  url == "secuencia" ? <></> :
                <footer className="items-end bg-gray-800 text-white p-6 lg:pt-8 lg:pb-8 lg:pl-12 lg:pr-12  flex flex-wrap justify-center lg:justify-end">
                    <p className="lg:mb-0 mb-5 lg:mr-5">Esta es una plataforma del programa</p>
                    <img className="mr-5 h-16" src={theme === 'jubicom' ? logoJubicom : logo} alt="logo" draggable="false"/>
                    <img src={dafi} alt="dafi" className="mr-5 h-16" draggable="false"/>
                </footer> 
            }
        </>
    );
}  
 
export default Footer;