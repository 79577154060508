// import { combineReducers } from "redux";
// import { config } from "../../config/user_token";
import api_iac from "../../config/instance_axios";
import { getAreaName } from "../../helpers/comite";

export const events_service = {
    get_all_events,
    get_user_group_events,
};

let user = JSON.parse(localStorage.getItem("user"));

async function get_all_events() {
    const areaname = getAreaName(user.fideicomiso, user.comite);

    const request_events = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_get_all_events&
    moodlewsrestformat=json&
    userid=${user.id}${areaname ? "&areaname=" + areaname : ""}`);

    if (Array.isArray(request_events.data)) {
        const events = request_events.data;
        return { events };
    }

    const error = request_events.data;
    return { error };
}

async function get_user_group_events() {
    const request_events = await api_iac.get(
        "webservice/rest/server.php?wstoken=" +
            user.token +
            "&wsfunction=targett_get_user_group_events&moodlewsrestformat=json&userid=" +
            user.id
    );

    if (Array.isArray(request_events.data)) {
        const events = request_events.data;
        return { events };
    }

    const error = request_events.data;
    return { error };
}
