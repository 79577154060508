export const messages = {
    allDay: "Todo el día",
    previous: "<",
    next: ">",
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
    agenda: "Agenda",
    date: "Fecha",
    time: "Hora",
    event: "Evento",
    noEventsInRange: "No hay eventos en este rango",
    showMore: (total) => `+ Ver más (${total})`,
};

export const formatEvents = (mettings, events) => {
    //if (!mettings || !mettings.length || !events || !events.length) return [];

    events = events
        .filter((item) => item.tipo === "course")
        .map((value) => {
            return {
                id: value.id,
                title: value.title,
                start: value.start,
                end: value.end,
                notes: value.notes,
                area: value.area,
                namearea: value.namearea,
                bgcolor: value.bgcolor,
                tipo: value.tipo,
                idtipo: value.idtipo,
                mode: value.mode,
                fideicomiso: value.fideicomiso,
            };
        });
    mettings.forEach((element) => {
        var l_date = new Date(element.start_time);
        var l_newelement = {
            id: 10000 + element.id,
            title: element.topic,
            start: element.start_time,
            notes: "",
            area: 0,
            namearea: "",
            bgcolor: "#367CF7",
            tipo: "meeting",
            idtipo: element.id,
            mode: "Virtual",
            linktipo: element.join_url,
            fideicomiso: "",
            end: l_date.getTime() + element.duration * 60000
        };

        // l_newelement.end = new Date(
        //     l_date.getTime() + element.duration * 60000
        // );

        events.push(l_newelement);
    });

    return events;
};

export const getDateEvents = (events) => {
    if (!events || !events.length) return [];
    const event_date = events.map((event) => ({
        from: new Date(event.start),
        to:  new Date(event.end),
        color: "#367CF7"
    }));

    // event_date.push({
    //     from: new Date(2022, 8, 17),
    //     to: new Date(2022, 8, 19),
    // })
    // event_date.push({
    //     from: new Date(2022, 8, 23),
    //     to: new Date(2022, 8, 24),
    // })

    return event_date;
};

export const filterDateInDate = (events, date) => {
    if (!events || !events.length || !date) return []

    events = events.filter((event) => validateDateRange(event.start, event.end, date))
    return events
}

// Verifica si una fecha esta en un rango de fechas.
export const validateDateRange = (start, end, date) => {
    start = new Date(start.substring(0, 10)).getTime();
    end = new Date(end).getTime();
    date = date.getTime();
    console.log(date, start, end)
    if (date >= start && date <= end) return true;

    return false
}

// const getDaysFromTo = (from, to) => {
//     const [inicio, fin] = [from.getTime(), to.getTime()];

//     // (1000*60*60*24) --> milisegundos -> segundos -> minutos -> horas -> días
//     const diferencia = (fin - inicio) / (1000 * 60 * 60 * 24);

//     return diferencia;
// };
