export const recompensas = {
    RECOMPENSAS_REQUEST: 'RECOMPENSAS_REQUEST',
    RECOMPENSAS_SUCCESS: 'RECOMPENSAS_SUCCESS',
    RECOMPENSAS_FAILURE: 'RECOMPENSAS_FAILURE',

    RECOMPENSAS_EXCHANGE_REQUEST: 'RECOMPENSAS_EXCHANGE_REQUEST',
    RECOMPENSAS_EXCHANGE_SUCCESS: 'RECOMPENSAS_EXCHANGE_SUCCESS',
    RECOMPENSAS_EXCHANGE_FAILURE: 'RECOMPENSAS_EXCHANGE_FAILURE',
}

export const canjeados = {
    RECOMPENSAS_GET_EXCHANGE_REQUEST: 'RECOMPENSAS_GET_EXCHANGE_REQUEST',
    RECOMPENSAS_GET_EXCHANGE_SUCCESS: 'RECOMPENSAS_GET_EXCHANGE_SUCCESS',
    RECOMPENSAS_GET_EXCHANGE_FAILURE: 'RECOMPENSAS_GET_EXCHANGE_FAILURE',
}

