import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api_iac from '../../config/instance_axios';
import moment from 'moment';
import 'moment/locale/es'
import Swal from 'sweetalert2';

import { communications_actions } from '../../store/_actions/communications';



const Communication = (props) => {
    const dispatch = useDispatch();
    const { channelid } = props.match.params;

    useEffect(() => {
        const getCommunications = () => dispatch(communications_actions.get_all_communications(channelid));
        getCommunications();
    }, []);

    const user = useSelector(state => state.authentication.user);
    const datacommunications = useSelector(state => state.communications.communications);

    const convertUnixtoDate = (unixTime) => {
        moment.locale('es')
        let formatTime = moment.unix(unixTime).format('dddd D MMMM YYYY, h:mm A')
        return formatTime;
    }


    return (
        <div className="container mx-auto px-4 h-auto my-8">
            <div className='addNewCard mb-20'>
                <h3 className='text-4xl font-black text-blue-500 mr-5'>Canal de comunicación</h3>
            </div>
            <hr style={{ color: '#a9a9a9', backgroundColor: '#a9a9a9', height: 1, borderColor: '#a9a9a9', }} />

            {datacommunications.map((communication, index) => (
                <>
                    <div className="cardDiscussion mt-10">
                        <div className='autorCard'>
                            <div className="rounded-full bg-cover" style={{ width: '40px', height: '40px', backgroundColor: '#e6e6e6', backgroundImage: `url(${communication.userpictureurl})` }}> </div>
                            <div className='autorContent ml-3'>
                                <span className='text-2xl font-black text-blue-500'>{communication.subject}</span>
                                <p>De: {communication.userfullname} - {convertUnixtoDate(communication.created)}</p>
                            </div>
                        </div>
                        <div className='messageForum my-5'>
                            <p>{communication.message}</p>
                        </div>
                    </div>
                </>
            ))

            }
        </div>
    );
}



export default Communication;