import React from 'react';


const ActividadesRecientes = ({ texto }) => {
    return ( 
        <div className="w-full md:w-1/2 lg:w-1/3 mb-5 lg:pl-6 lg:pr-6">
            <div className="text-blue-500 font-black text-2xl mb-2">Privacidad y politicas</div>
            <div>
                <div className="mb-3">
                    <p className="text-gray-800">Resumen conservación de datos</p>
                </div>
            </div>
        </div>
    );
}
 
export default ActividadesRecientes;