import { events_constants } from '../_constants/calendar';
import { events_service } from '../_services/calendar';
import { alertActions } from './alert';

export const events_actions = {
    get_all_events,
    get_user_group_events
};

function get_all_events() {
    return dispatch => {
        dispatch(request());

        events_service.get_all_events()
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.events));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: events_constants.EVENTS_REQUEST, payload: true } }
    function success(param) { return { type: events_constants.EVENTS_SUCCESS, payload: param } }
    function failure(error) { return { type: events_constants.EVENTS_FAILURE, payload: error } }
}

function get_user_group_events() {

   return dispatch => {
       
        dispatch( request() );

        events_service.get_user_group_events()
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.events));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())));
    };

    function request() { return { type: events_constants.EVENTS_REQUEST, payload: true } }
    function success(param) { return { type: events_constants.EVENTS_SUCCESS, payload: param } }
    function failure(error) { return { type: events_constants.EVENTS_FAILURE, payload: error } }
}
