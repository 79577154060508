import axios from "axios";

const api_iac = axios.create({
    baseURL: 'https://universidaddafi.com.mx/corelms/',
    //baseURL: 'http://localhost:8888/corelms/',
    //baseURL: 'http://dafi.test/corelms/',
    //baseURL: 'https://universidaddafi.com.mx/desarrollo/',
    //timeout: 50000,
});


export default api_iac;