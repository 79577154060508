import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import api_iac from '../../config/instance_axios';

const Tema = (props) => {
    const history = useHistory();
    console.log(localStorage);
    const { instancia ,id } = props.match.params;
    const user = useSelector(state => state.authentication.user );

    const [res_html, set_res_html] = useState('');
    const detalles = async () => {
        const request_detalles = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=targett_get_info_scorm&id=${id}&moodlewsrestformat=json&userid=${user.userid}`)

        const res = await request_detalles.data;
        console.log(res);
        set_res_html(res)
    }

    useEffect(() => {
        detalles();
    },[]);

    const redireccionar = () => {
        history.push(`/secuencia/${instancia}`)
    }
    return ( 
        <div className="bg-white p-4">
            <div className="mb-4"> <h3 className="text-4xl font-black text-blue-500">{localStorage.getItem('tema')}</h3> </div>

            <div className="text-gray-800 mb-10" dangerouslySetInnerHTML={{ __html: res_html.info_scorm  }}></div>

            {
                res_html.exceededmaxattempts == true ?
                    <div> <h3 className="text-2xl font-black text-blue-500">Ha alcanzado el número máximo de intentos</h3> </div>
                :   
                    <button className="bg-blue-500 rounded-2xl px-8 py-2 text-white"> <p onClick={ () => redireccionar()}>Entrar</p> </button>
            }

            <div className="mb-16"></div>
        </div>
    );
}
 
export default Tema;