import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Aside from "../../Components/Aside/Aside";
import Ranking from "../../Components/Ranking/Ranking";
import ListSlider from "../../Components/Slider/ListSlider";
import CarouselList from "../../Components/Slider/CarouselList";
import { cursos_actions } from "../../store/_actions/cursos";
import { rankings_actions } from "../../store/_actions/ranking";
import { reconocimientos_actions } from "../../store/_actions/reconocimientos";
import Swal from "sweetalert2";

import { DayPicker } from 'react-day-picker';
import MinCalendar from "../../Components/Calendario/MinCalendar";

const MAX_VIDEOS_TO_SHOW = 3;

const Inicio = (props) => {
    // Redux
    const dispatch = useDispatch();

    useEffect(() => {
        try {
            console.log(
                "******************************************* ENTER Start section *****************************************"
            );
            const get_cursos_inscritos = () =>
                dispatch(cursos_actions.getCursosInscritos());
            const getReconocimientos = () =>
                dispatch(reconocimientos_actions.getreconocimientos());
            const get_ranking = () => dispatch(rankings_actions.get_ranking());
            const get_rankings = () =>
                dispatch(rankings_actions.get_rankings());
            get_cursos_inscritos();
            getReconocimientos();
            get_ranking();
            get_rankings();
        } catch (loadingException) {
            Swal.fire({
                title: "Error",
                text:
                    "Hubo un problema cargando alguno de los datos iniciales" +
                    "\n<br /> " +
                    loadingException.message,
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    }, []);

    const cursos_disponibles = useSelector((state) => state.cursos.cursos);
    const cursos = cursos_disponibles.filter(
        (item) => item.progress == 0 || item.progress == null
    );

    let theme = localStorage.getItem("theme");

    return (
        <>
            <div className="flex flex-col flex-wrap sm:flex-row">
                <Aside props={props} />

                <div
                    className={`flex ${
                        theme === "jubicom" ? "bgMainJubicom" : "bgMainDafi"
                    } flex-wrap flex-col justify-center w-full h-full md:w-3/4 lg:w-4/5`}
                >
                    {/*<div className="w-full overflow-hidden">
                    { cursos.length > 0 ? <ListSlider title="Mis cursos disponibles" cursos={cursos} MAX_VIDEOS_TO_SHOW={MAX_VIDEOS_TO_SHOW } text="iniciar" /> : null}
                </div>*/}

                    <div className="w-full">
                        {cursos.length > 0 ? (
                            <CarouselList
                                title="Mis cursos disponibles"
                                cursos={cursos}
                                text="iniciar"
                            />
                        ) : null}
                    </div>

                    <div className="flex flex-wrap justify-center w-full lg:flex-nowrap">
                        <div className="m-5 w-full md:w-2/3 lg:w-2/5">
                            {/* <Calendario/> */}
                            <MinCalendar />
                        </div>
                        <div className="m-5 w-full md:w-2/3 lg:w-3/5">
                            <Ranking />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Inicio;
