import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import desplegar_menu from '../../assets/img/desplegar_menu.svg'
import { userAuthActions } from '../../store/_actions/user_auth';


const MenuOpciones = () => {
    const node = useRef();
    const [menu, setMenu] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => document.removeEventListener("mousedown", handleClick);
    }, []);

    const handleClick = e => {
        if (node.current != undefined) {
            if(node.current.contains(e.target) == false ){
                setMenu(false)
            }
        }
    };

    const logoutUser = () => {
        dispatch(userAuthActions.logout());
    }

    const authentication = useSelector(state => state.authentication );

    return ( 
        <>
            <div className="menu-opciones-perfil">
                <div onClick={() => setMenu(!menu) } className="flex items-center">
                    <div style={{ width: '45px', height: '45px', backgroundImage: `url(${authentication.user.profileimage})` }} className="bg-cover bg-center rounded-full userPhoto mr-4 ml-2"></div>
                    <div> <img className="hidden lg:block" src={desplegar_menu} alt="" draggable="false"/> </div>
                </div>

                { menu ? 
                    <div ref={node} onClick={handleClick} className="menu-opciones-perfil-items bg-white">
                        <ul>
                            <li className="p-1"> <Link className="text-xs" to="/mi-perfil">Mi perfil</Link> </li>
                            <li onClick={logoutUser} className="p-1"> <Link className="text-xs">Cerrar sesión</Link> </li>
                        </ul>
                    </div>
                : null }
            </div>
            
        </>
    );
}
 
export default MenuOpciones;