import React from 'react';
import { useSelector } from 'react-redux';


const DetallesUsuario = () => {

    const authentication = useSelector(state => state.authentication);

    return (
        <div className="w-full md:w-1/2 lg:w-1/3 mb-5 lg:pl-6 lg:pr-6">
            <div className="text-blue-500 font-black text-2xl">Detalles de usuario</div>
                <div className="mb-3">
                    <p className="text-black uppercase">Direccion Email</p>
                    <p className="text-blue-500"> {authentication.user.email} </p>
                </div>
                <div className="mb-3">
                    <p className="text-black uppercase">Pais</p>
                    <p className="text-blue-500"> {authentication.user.country} </p>
                </div>
                <div className="mb-3">
                    <p className="text-black uppercase">Perfil </p>
                    <p className="text-blue-500"> <span className="text-black">Nombre: </span> {`${authentication.user.firstname} ${authentication.user.lastname}`} </p>
                    <p className="text-blue-500"> <span className="text-black">Username: </span> {authentication.user.username} </p>
                    <p className="text-blue-500"> <span className="text-black">Fideicomiso: </span> {authentication.user.fideicomiso} </p>
                    <p className="text-blue-500"> <span className="text-black">Órgano: </span> {authentication.user.comite} </p>
                </div>
        </div>
    );
}

export default DetallesUsuario;
