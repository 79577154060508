import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import buscador from '../../assets/img/buscador.svg'

const BuscadorMobile = () => {
    const node = useRef()
    const [open, setOpen] = useState(false)
    const [busqueda, guardarBusqueda] = useState('');
    const [busquedaRes, guardarBusquedaRes] = useState([]);
    const [busquedaShow, guardarBusquedaShow] = useState(false);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => document.removeEventListener("mousedown", handleClick);
    }, []);

    const handleClick = e => {
        if (node.current != undefined) {
            if (node.current.contains(e.target) == false) {
                setOpen(false)
            }
        }
    };

    const obtenerCursos = useSelector(state => state.cursos.cursos);
    const history = useHistory();

    const redireccionar = (id) => {
        guardarBusquedaShow(false)
        history.push(`/curso/${id}`)
        window.location.reload();
    };

    const handleChange = e => {
        guardarBusqueda(e.target.value)

        if (busqueda != '' && busqueda != undefined) {
            const resultado = obtenerCursos.filter(item => {
                if (item) {
                    return item.fullname.toLowerCase().search(busqueda.toLowerCase()) !== -1;
                }
            });
            guardarBusquedaRes(resultado);
        } else {
            guardarBusquedaRes(obtenerCursos)
        }
    }

    return (
        <div className="content_search">
            <div onClick={() => setOpen(!open)} className="mr-1" style={{ cursor: 'pointer' }}>
                <div> <img style={{ width: '30px' }} src={buscador} alt="buscador" draggable="false"/> </div>
            </div>

            {open ?
                <div ref={node} onClick={handleClick} className="search flex justify-center">
                    <input onFocus={() => guardarBusquedaShow(true)} onChange={handleChange} name="busqueda" className="p-1 rounded bg-white bg-opacity-50" type="text" placeholder="Escribe tu búsqueda" />
                </div>
                : null}
                {busquedaRes.length > 0 && busquedaShow === true ?
                    <div className="resultSearchBar shadow-sm" style={{ transform: 'translate(30px)' }}>
                        {busquedaRes.map(item => (
                            <div onClick={() => redireccionar(item.id)} className="p-1 text-black cursor-pointer hover:bg-gray-400"> <p className="text-sm">{item.fullname}</p> </div>
                        ))}
                    </div>
                    : null}
        </div>
    );
}

export default BuscadorMobile;