import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import cursos_iniciados from '../../../assets/img/cursos_iniciados.png'
import cursos_terminados from '../../../assets/img/cursos_terminados.png'
import CursoIniciado from './Curso/CursoIniciado';
import CursoTerminado from './Curso/CursoTerminado';
import Promedio from './Curso/Promedio';

import { Link } from 'react-router-dom'

const Cursos = () => {

    const cursos_inscritos = useSelector(state => state.cursos.cursos);

    const cursos_Iniciados = cursos_inscritos.filter(item => item.progress < 100 && item.progress > 0);
    const cursos_Terminados = cursos_inscritos.filter(item => item.progress == 100);

    let total = 0;

    cursos_inscritos.forEach(item => {
        total += item.grade == "-" ? 0 : Number.parseFloat(item.grade);
    });

    const promedio = Number(total / cursos_inscritos.length).toFixed(1);


    return (
        <>
            <h2 className="text-blue-500 font-black uppercase mt-7">Mis Cursos</h2>

            <div className="flex justify-center flex-col items-center w-full mt-2">

                <div className="bg-white rounded-xl w-full p-2 mt-2">

                    {/* Cursos Iniciados */}
                    <div className="flex">
                        <img src={cursos_iniciados} alt="" draggable="false"/>
                        <div className="flex-1"> <p className="text-right text-xs text-gray-600"> <Link to="cursos">Ver cursos</Link> </p> </div>
                    </div>

                    <div className="flex mt-2">

                        <div className="flex text-blue-500">
                            <div className="mr-2"> <p className="text-xl font-black uppercase">0{cursos_Iniciados.length}</p> </div>
                            <div className="break-all"> <p className="text-xl uppercase font-bold">Cursos <span className="text-xl font-bold uppercase lg:hidden md:hidden">iniciados</span></p><p className="text-xl font-bold uppercase hidden lg:block md:block">iniciados</p> </div>
                        </div>
                    </div>

                    <div>
                        {cursos_Iniciados == undefined ? 'Cargando...'
                            : cursos_Iniciados.length > 0 ? cursos_Iniciados.map(curso => <CursoIniciado key={curso.id} curso={curso} />)
                                : <p className="flex my-3 text-base text-gray-600">No tienes cursos iniciados</p>
                        }
                    </div>
                </div>


                {/* Cursos Terminados */}
                <div className="bg-white rounded-xl w-full p-2 mt-4">
                    <div className="flex">
                        <img src={cursos_terminados} alt="" draggable="false"/>
                        <div className="flex-1"> <p className="text-right text-xs text-gray-600"> <Link to="cursos">Ver cursos</Link> </p> </div>
                    </div>

                    <div className="flex mt-2">
                        <div className="flex text-blue-500">
                            <div className="mr-2"> <p className="text-xl font-black uppercase">0{cursos_Terminados.length}</p> </div>
                            <div className="break-all"> <p className="text-xl uppercase font-bold">Cursos <span className="text-xl font-bold uppercase lg:hidden md:hidden">terminados</span></p><p className="text-xl font-bold uppercase hidden lg:block md:block">terminados</p> </div>
                        </div>
                    </div>

                    <div>
                        {cursos_Terminados == undefined ? 'Cargando...'
                            : cursos_Terminados.length > 0 ? cursos_Terminados.map(curso => <CursoTerminado key={curso.id} curso={curso} />)
                                : <p className="flex my-3 text-base text-gray-600">No tienes cursos terminados</p>
                        }
                    </div>

                    <Promedio promedio={promedio} />

                </div>
            </div>
        </>
    );
}

export default Cursos;