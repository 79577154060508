import React from 'react';
import { meetingLog } from '../../store/_services/meetingLog';

import zoom from '../../assets/img/zoom.jpg';

function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    let newFormat = new Date(date).toLocaleDateString("es-ES", options);
    return newFormat;
}


const VideoConferencia = ({ idMeeting, url_video, texto, fecha }) => {

    return (

        <div style={{ maxWidth: '300px' }} className="mx-6 p-2 w-full sm:w-1/3 md:w-1/4 lg:w-1/5">
            <div className="overflow-hidden rounded-lg shadow-2xl border">
                        <img className="w-full" src={zoom}
                            alt="Sunset in the mountains" draggable="false"/>
                        <div className="flex items-center justify-end leading-tight p-2 md:p-4">
                            <p className="text-grey-darker text-sm">
                                {
                                    formatDate(fecha)
                                }
                                <i className="far fa-clock ml-1"></i>
                            </p>
                        </div>
                        <div className="flex items-center justify-between leading-none p-2 md:p-4">
                            <div className="flex items-center">
                                <h1 className="text-lg text-blue-500">
                                    {texto}
                                </h1>
                            </div>

                        </div>
                        <div className="px-6 py-4 flex justify-center">
                            <button className="inline-block px-3 py-1 text-12 font-medium leading-6 text-center text-white uppercase transition bg-blue-500 rounded-full shadow ripple hover:shadow-lg hover:bg-blue-800 focus:outline-none" onClick={() => meetingLog.register_log_meeting(idMeeting, url_video)}>Ver conferencia</button>
                        </div>
                    </div>
        </div>


    );
}

export default VideoConferencia;