import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Reconocimiento from '../../Components/Reconocimientos/Reconocimiento';
import Tag from '../../Components/Tag';
import { reconocimientos_actions } from '../../store/_actions/reconocimientos';
import {ReactComponent  as Loading } from "../../assets/loading.svg";
import { rankings_actions } from '../../store/_actions/ranking';

const Recompensas = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const getReconocimientos = () => dispatch(reconocimientos_actions.getreconocimientos());
        const getReconocimientosAll = () => dispatch(reconocimientos_actions.get_all_reconocimientos());
        dispatch(rankings_actions.get_ranking());
        getReconocimientos();
        getReconocimientosAll();
    }, [])

    const reconocimientos_user = useSelector(state => state.reconocimientos.reconocimientos);
    const reconocimientos_all = useSelector(state => state.reconocimientos.reconocimientos_all);

    const reconocimientos_loading = useSelector(state => state.reconocimientos.reconocimientos_loading);
    const reconocimientos_all_loading = useSelector(state => state.reconocimientos.reconocimientos_all_loading);
    let reconocimientos;

    if (reconocimientos_loading == false && reconocimientos_all_loading == false && reconocimientos_all !== undefined) {
        console.log("badge user", reconocimientos_user);
        reconocimientos = reconocimientos_all.filter((item, i) => {
            item.success = false;
            reconocimientos_user.find(badge =>{
                if(badge.id === item.id){
                    item.success = true;
                }
            });
            return item;
        })
    }
console.log("reconocimientos",reconocimientos);
    const token = useSelector(state => state.authentication.user.token);
    return (
        <>
            { reconocimientos_all_loading === true ? 
                <div style={{ height: '50vh' }}  className='flex justify-center items-center flex-col flex-wrap w-full h-full'>
                    <Loading className='animate-spin h-16 w-16 inline-block text-blue-500'/>
                </div>
                :
                <>
                        {/* <Nav/> */}
                        <div className="flex justify-center items-center md:items-center lg:items-end lg:flex-row md:justify-center lg:justify-end">
                            {reconocimientos_loading == false && reconocimientos_all_loading == false ?
                                <Tag imagen="reconocimientos.png" type="RECONOCIMIENTOS" number={reconocimientos_user.length} />
                                : null}
                        </div>
                        <div className="container mx-auto flex justify-center items-center lg:items-start lg:justify-start">
                            <div className="p-4 text-center lg:text-left">
                                <h3 className="text-2xl uppercase text-blue-500 font-black mb-5">
                                    Mis Reconocimientos
                                </h3>
                            </div>
                        </div>

                        <div className="container mx-auto flex flex-wrap w-full mt-5 mb-5">
                            {reconocimientos_loading == false && reconocimientos_all_loading == false && reconocimientos_all !== undefined ?
                                reconocimientos.map(item => (
                                    <Reconocimiento url_img={`${item.badgeurl}?token=${token}`} lock={item.success} name={item.name} text={item.description} />
                                ))
                                : null}
                        </div>

                        {/* <Footer/> */}
                </>
            }
        </>
    );
}

export default Recompensas;