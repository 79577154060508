import React from 'react';
import trofeo from '../../../../assets/img/trofeo.png'
import trofeoJubicom from '../../../../assets/img-jubicom/trofeo.png'
import plus from '../../../../assets/img/plus.svg'
import { Link } from 'react-router-dom';

let theme = localStorage.getItem('theme');

const Promedio = ({ promedio }) => {
    return (
        <div className={`flex ${theme === 'jubicom' ? "bgJubicom" : "bgDafi"} flex-wrap-reverse items-center justify-evenly mb-3 p-4 rounded-xl`}>
            <div>
                <img src={theme === 'jubicom' ? trofeoJubicom : trofeo} alt="" draggable="false"/>
            </div>
            <div className="text-right">
                <div className="flex flex-row items-center mb-5 text-right justify-end"> <img className="mr-1" style={{ width: '10px', height: '10px' }} src={plus} alt="" draggable="false"/> <p className="uppercase text-xs text-white "><Link to="calificaciones">ver más</Link></p> </div>
                <p className="text-xl text-white">Promedio</p>
                <p className="text-white text-6xl">{promedio == "NaN" ? "0" : promedio}</p>
                <p className="text-xl text-white mb-2">Calificaciones</p>
            </div>
        </div>
    );
}

export default Promedio;