import React from 'react';
import { useHistory } from 'react-router';



const CursoIniciado = ({ curso }) => {
    const history = useHistory();

    const route = history.location.pathname;

    const redireccionar = (id) => history.push(`curso/${id}`);

    return ( 
        <div className={route === '/inicio' || route === '/eventos' || route === '/cursos' ? 'flex justify-between mb-3 mt-2' : 'flex items-center justify-between my-3'}>
            <div className="w-7/12 lg:w-9/12 md:w-8/12 sm:w-7/12" style={{ wordWrap: "break-word" }}>
                <p className="text-sm uppercase mb-2">{ curso.fullname }</p>

                <div className={route === '/inicio' || route === '/eventos' || route === '/cursos' ? 'h-2 relative w-full rounded-full overflow-hidden mb-2' : 'h-4 relative w-10/12 rounded-full overflow-hidden mb-2'}>
                    <div className="w-full h-full bg-gray-700 absolute"></div>
                    <div className="h-full bg-green_bar absolute" style={{ width: `${curso.progress}%` }}></div>
                </div>

                <p className="text-xs text-gray-600 cursor-pointer" onClick={ () => redireccionar(curso.id) }> Continuar curso </p>
            </div>

            <div className="flex flex-col mt-1">
                { route === '/inicio' || route === '/eventos' || route === '/cursos' 
                ? <p className="text-right text-xs text-gray-800 mb-1">Avance</p> : null
                }

                <div className="w-2/12">
                    <p className={route === '/inicio' || route === '/eventos' || route === '/cursos' ? "text-blue-500 text-12" : 'text-gray-700'}>{ Number.parseFloat(curso.progress).toFixed(2) }%</p>
                </div>
            </div>
            
        </div>
    );
}
 
export default CursoIniciado;