import React from 'react';


const ItemRanking = ({item}) => {
    return ( 
        <div>
            <div className="flex p-4 items-center text-center">
                <div className="flex w-full items-center justify-between">
                    <div className="w-2/12"> <span>{item.rankposition}</span> </div>
                    <div className="rounded-full bg-cover" style={{ width: '40px', height: '40px', backgroundColor: '#e6e6e6' , backgroundImage: `url(${item.profileimage})` }}> </div>
                    <div className="w-8/12" > <p>{ item.firstname } { item.lastname}</p> </div>
                </div>
                <div className="w-4/12">  <span>{item.rankpoints} Puntos</span> </div>
            </div>
            <hr/>
        </div>
    );
}
 
export default ItemRanking;