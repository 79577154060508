import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Nav from '../Nav/Nav';
import HeaderPerfil from '../Nav/HeaderPerfil';
import Footer from '../Footer';
import { useDispatch, useSelector } from 'react-redux';
import { events_actions } from '../../store/_actions/calendar';
import { meetings_actions } from '../../store/_actions//conferencias';



export const PrivateRoute = ({ component: Component, ...rest }) => {

    const dispatch = useDispatch();
    dispatch(events_actions.get_all_events());
    dispatch(meetings_actions.get_meetings());

    return (

        <Route {...rest} render={props => (
            localStorage.getItem('user')
                ?
                <div>
                    <Nav />
                    <HeaderPerfil props={props} />
                    <Component {...props} />
                    <Footer props={props} />
                </div>
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
    );
}