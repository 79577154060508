import { config } from '../../config/user_token';
import api_iac from '../../config/instance_axios';

export const userService = {
    login,
    logout,
    register,
};

async function login(username, password) {

    // Solicitamos un token 
    let dataUser = new FormData();
    dataUser.append('username', username);
    dataUser.append('password', password);
    dataUser.append('service', "targett_usuario");

    const request_token = await api_iac.post("login/tokenwebservice.php",dataUser);

    if (request_token.data.token != undefined) {
        const token = request_token.data.token;

        // Consultamos a nuestro usuario con el token anterior
        const request_user = await api_iac.get(`webservice/rest/server.php?wstoken=${token}&wsfunction=targett_api_get_current_user&moodlewsrestformat=json`);
        const user = request_user.data;
        
        if ( (user.istutor !== true) && (user.isadmin !== true) ) {
            // Guardamos el inicio de sesión
            await api_iac.post('reportador/api.php?q=saveLoggedinLog', { userid: user.userid })
        }

        /*if (user.istutor === true || user.isadmin === true) {
            let tutor = true;
            return { tutor };
        }*/

        user.id = user.userid;
        user.token = token;
        user.password = password;

        // Guardamos el token y el usuario en localStorage
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
        localStorage.setItem('theme', user.theme);
        
        return { user };
    }

    const error = request_token.data.error;
    return { error };
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}