import api_iac from '../../config/instance_axios';


export const meetingLog = {
    register_log_meeting
};

let user = JSON.parse(localStorage.getItem('user'));

async function register_log_meeting(idMeeting, urlZoom) {

    const token = user.token;
    const id = user.userid;

    const consulta = await api_iac.post(`webservice/rest/server.php?
            wstoken=${token}&
            wsfunction=targett_log_users_meeting_zoom&
            meeting[0][userid]=${id}&
            meeting[0][meetingid]=${idMeeting}&
            moodlewsrestformat=json`
    ).then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.meeting[0]['status'] === 'Success') {
            window.open(urlZoom, '_blank');
        }
    }).catch(function (error) {
        console.log(error);
    });
}