import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ItemRanking from './ItemRanking';

import ordinal from 'ordinales';

const Ranking = () => {
    const user = useSelector(state => state.authentication.user);
    const ranking = useSelector(state => state.rankings);
    const [Item, setItem] = useState([]);
    let theme = localStorage.getItem('theme');
    
    useEffect(()=>{
        let item = ranking.rankings.filter(item => item.userid === user.userid);
        setItem(item);
    },[ranking]);
    
    return (
        <>
            {ranking.ranking_loading == false && ranking.rankings_loading == false ?
                <div>
                    <div className="mb-3"> <h4 className="text-blue-500 text-4xl" >Ranking</h4> </div>

                    <div className={`${theme === 'jubicom' ? "bgDafi" : "bgJubicom"} hm-shadow rounded-t-xl flex w-full justify-between text-center items-center p-4 text-white`}>
                        <div className="w-1/3 font-black text-2xl">
                            <p>{Item.length === 0 ? 0 : ordinal(Item[0].rankposition, "male")}</p>
                        </div>
                        <div className="w-1/3 rounded-full bg-cover bg-center" style={{ width: '60px', height: '50px', backgroundImage: `url(${user.profileimage})` }}>
                            <img src="" alt="" draggable="false"/>
                        </div>
                        <div className="w-1/3 font-black text-2xl">
                            <p>{Item.length === 0 ? 0 : Item[0].rankpoints} Puntos</p>
                        </div>
                    </div>

                    <div className="hm-shadow bg-gray-300 rounded-b-2xl max-h-96 overflow-x-auto">
                        {ranking.rankings.map(item => (<ItemRanking key={item.id} item={item} />))}
                    </div>
                </div>
                : null}
        </>
    );
}

export default Ranking;