import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Aside from '../../Components/Aside/Aside';
import Ranking from '../../Components/Ranking/Ranking';
import ListSlider from '../../Components/Slider/ListSlider';
import CarouselList from "../../Components/Slider/CarouselList";
import { cursos_actions } from '../../store/_actions/cursos';
import { reconocimientos_actions } from '../../store/_actions/reconocimientos';
import {ReactComponent  as Loading } from "../../assets/loading.svg";

import { rankings_actions } from '../../store/_actions/ranking';

const MAX_VIDEOS_TO_SHOW = 2;

const Cursos = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const get_cursos_inscritos = () => dispatch(cursos_actions.getCursosInscritos());
        const getReconocimientos = () => dispatch(reconocimientos_actions.getreconocimientos());
        dispatch(rankings_actions.get_ranking())
        getReconocimientos();
        get_cursos_inscritos();
    }, []);

    const cursos = useSelector(state => state.cursos.cursos);
    const loading = useSelector(state => state.cursos);

    const cursos_Iniciados = cursos.filter(item => item.progress < 100 && item.progress > 0);
    const cursos_Terminados = cursos.filter(item => item.progress === 100);
    const cursos_disponibles = cursos.filter(item => item.progress === 0 || item.progress == null );
    
    let theme = localStorage.getItem('theme');

    return (
        <>
            <div className="flex flex-col flex-wrap sm:flex-row">
                <Aside />

                { loading.loading === true ? 
                        <div  className='flex justify-center items-center flex-col flex-wrap w-3/4'>
                        <Loading className='animate-spin h-16 w-16 inline-block text-blue-500'/>
                    </div> : 
                    <div className={`flex ${theme === 'jubicom' ? "bgMainJubicom" : "bgMainDafi"} 
                    flex-wrap flex-col justify-center w-full md:w-3/4 lg:w-4/5 lg:flex-nowrap`}>
                        <div className="w-full">
                            {cursos_disponibles.length > 0  ? <CarouselList title="Mis cursos disponibles" cursos={cursos_disponibles} text="iniciar" /> : null}
                        </div>

                        <div className="w-full overflow-hidden">
                            {cursos_Iniciados.length > 0 ? <CarouselList title="Continuar curso" cursos={cursos_Iniciados} text="continuar curso" /> : null}
                            {cursos_Terminados.length > 0 ? <CarouselList title="Cursos finalizados " cursos={cursos_Terminados} text="ver nuevamente" /> : null}
                        </div>
                    </div>
                }
                
            </div>
        </>

        
    );
}

export default Cursos;