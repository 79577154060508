import { meetings } from '../_constants/conferencias';


const initialState = {
    meetingsAll: [],
};

export function meetings_reducers(state = initialState, action) {
    switch (action.type) {
        case meetings.MEETINGS_REQUEST:
            return {
                ...state,
                meetings_loading: action.payload,
            };
        case meetings.MEETINGS_SUCCESS:
            return {
                ...state,
                meetings_loading: false,
                meetingsAll: action.payload
            };
        case meetings.MEETINGS_FAILURE:
            return {
                ...state,
                meetings_loading: false,
                error: action.payload
            };
        default:
            return state
    }
}