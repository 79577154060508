import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api_iac from '../../config/instance_axios';

const Dctres = (props) => {
    const { courseid, dctresid } = props.match.params;
    const [urldctres, setUrldctres] = useState('')
    const [namedctres, setNamedctres] = useState('')


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        handleClick(courseid, user.token, dctresid)
    }, [])

    const handleClick = async (idcourse, token, idDctres) => {

        const consulta = await api_iac.post(`webservice/rest/server.php?
            wstoken=${token}&
            wsfunction=mod_dctres_get_issued_dctress&
            moodlewsrestformat=json&
            dctresid=${idDctres}`
        ).then(function (response) {

            const dctresItem = response.data;
            const dctresName = dctresItem.issues[0].filename;

            const urlDctres = dctresItem.issues[0].fileurl + `?token=${token}`;

            setUrldctres(urlDctres);
            setNamedctres(dctresName);
            
            window.location.assign(urlDctres);

        }).catch(function (error) {
            console.log(error);
        });
    }

    const user = useSelector(state => state.authentication.user);

    return (
        <div className="flex items-center justify-center mx-auto customContainerUrl bg-white">
            <p>En breve empezara la descarga del certificado dctress formato PDF. <br/> Haga clic en el enlace si aun no a inicado la descarga <a className="text-blue-500 cursor-pointer" onClick={() => handleClick(courseid, user.token, dctresid)} target="_blank" rel="noreferrer">{namedctres}</a>.</p>
        </div>
    );
}

export default Dctres;