import { meetings } from '../_constants/conferencias';
import { meetings_service } from '../_services/conferencias';

import { alertActions } from './alert';

export const meetings_actions = {
    get_meetings,
};

function get_meetings() {

    return dispatch => {
        dispatch(request());

        meetings_service.get_meetings()
            .then(
                meetings => {
                    if (meetings.length > 0) {
                        dispatch(success(meetings));
                    } else {
                        dispatch(failure(meetings.error.toString()));
                        dispatch(alertActions.error(meetings.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: meetings.MEETINGS_REQUEST, payload: true } }
    function success(meetingsAll) { return { type: meetings.MEETINGS_SUCCESS, payload: meetingsAll } }
    function failure(error) { return { type: meetings.MEETINGS_FAILURE, payload: error } }
}
