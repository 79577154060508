import DayPicker from "react-day-picker";
import st from "./calendar.module.css";
import "react-day-picker/lib/style.css";

import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import {
    filterDateInDate,
    formatEvents,
    getDateEvents,
} from "../../helpers/events";
import EventDetail from "./EventDetail";

const WEEKDAYS_SHORT = {
    es: ["D", "L", "M", "X", "J", "V", "S"],
};
const MONTHS = {
    es: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ],
};

const WEEKDAYS_LONG = {
    es: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
    ],
};

const FIRST_DAY_OF_WEEK = {
    es: 1,
};
// Translate aria-labels
const LABELS = {
    es: { nextMonth: "Proximo mes", previousMonth: "Mes anterior" },
};

function formatDate(date) {
    const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };
    let newFormat = new Date(date).toLocaleDateString("es-ES", options);
    return newFormat;
}

function formatDateActual(date) {
    const options = { month: "short", day: "numeric" };
    let newFormat = new Date(date).toLocaleDateString("es-ES", options);
    return newFormat;
}

const pastMonth = new Date();

const MinCalendar = ({ canShow = false }) => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const arrayEvents = useSelector((state) => state.calendar.events);
    const arrayMeetings = useSelector((state) => state.meetings.meetingsAll);

    const events = useMemo(
        () => formatEvents(arrayMeetings, arrayEvents),
        [arrayEvents, arrayMeetings]
    );

    const range = useMemo(() => getDateEvents(events), [events]);

    const eventsInDate = useMemo(
        () => filterDateInDate(events, currentDate),
        [currentDate, events]
    );
    // console.log(eventsInDate, "events >>>");
    // console.log(range, 'lista ')
    return (
        <div className={`${st.calendar_width} ${!canShow ? st.calendar_width_home : ''}`}>
            <DayPicker
                showOutsideDays
                showWeekNumbers
                todayButton="Ir al dia de hoy"
                months={MONTHS["es"]}
                weekdaysLong={WEEKDAYS_LONG["es"]}
                weekdaysShort={WEEKDAYS_SHORT["es"]}
                firstDayOfWeek={FIRST_DAY_OF_WEEK["es"]}
                labels={LABELS["es"]}
                defaultMonth={new Date()}
                initialMonth={new Date()}
                selectedDays={range}
                onDayClick={(e) => setCurrentDate(e)}
            />

            {(canShow && eventsInDate) && (
                <div className={st.calendar_events_list}>
                    {eventsInDate.map((event, index) => (
                        <EventDetail key={index} event={event} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default MinCalendar;
