import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userAuthActions } from '../../store/_actions/user_auth';
import { rankings_actions } from '../../store/_actions/ranking';


const HeaderPerfil = ({ props }) => {


    const { id } = props.match.params;
    let url = props.match.url.split("/")[1];

    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const lista_cursos = useSelector(state => state.cursos.cursos);

    const authentication = useSelector(state => state.authentication);

    console.log(authentication);
    const ranking = useSelector(state => state.rankings);
    const history = useHistory();
    const route = history.location.pathname;

    const logoutUser = () => {
        dispatch(userAuthActions.logout());
    }
    const curso = lista_cursos.filter(item => item.id == id);
    let texto = '';
    let fondo = '';

    // Test 2
    /* const modo = false;

    const jubicom = '#f6b900';
    const dafi = '#236da9';

    let style = { background: modo === true ? jubicom : dafi }; 
    <p class="uppercase py-2 px-4 bg-blue-400 rounded-l-full" style="background: ${style.background}">A tu entrenamiento en línea</p>

    */
    let theme = localStorage.getItem('theme');

    useEffect(() => {
        const get_rankings = () => dispatch(rankings_actions.get_rankings());
        get_rankings();
    },[]);

    switch (history.location.pathname.split('/')[1]) {
        case 'inicio':
            fondo = require('../../assets/img/header/inicio.jpg')
            texto = `<div class="toRigth-hm">
                        <p class="uppercase text-4xl mr-2">+ Bienvenido!</p> 
                        <p class="uppercase py-2 px-4 ${theme === 'jubicom' ? "jubicomHeaders" : "dafiHeaders"} rounded-l-full float-right">
                            A tu aprendizaje en linea
                        </p>
                    </div>`;
            break;
            case '':
            fondo = require('../../assets/img/header/inicio.jpg')
            texto = `<div class="toRigth-hm">
                        <p class="uppercase text-4xl mr-2">+ Bienvenido!</p> 
                        <p class="uppercase py-2 px-4 ${theme === 'jubicom' ? "jubicomHeaders" : "dafiHeaders"} rounded-l-full float-right">
                            A tu aprendizaje en linea
                        </p>
                    </div>`;
            break;
        case 'cursos':
            fondo = require('../../assets/img/header/cursos.jpg')
            texto = `<div class="toRigth-hm"> <p class="uppercase text-4xl mr-2">+ Bienvenido!</p> <p class="uppercase py-2 px-4 ${theme === 'jubicom' ? "jubicomHeaders" : "dafiHeaders"} rounded-l-full float-right">Mis cursos</p></div>`;
            break;
        case 'curso':
            fondo = require('../../assets/img/header/cursos.jpg')
            texto = `<div class="toRigth-hm"> <p class="uppercase text-4xl mr-2">+ Curso</p> <p class="uppercase py-2 px-4 ${theme === 'jubicom' ? "jubicomHeaders" : "dafiHeaders"} rounded-l-full float-right"></p></div>`;
            break;
        case 'eventos':
            fondo = require('../../assets/img/header/eventos.jpg')
            texto = `<div class="toRigth-hm"> <p class="uppercase text-4xl mr-2">+ Bienvenido!</p> <p class="uppercase py-2 px-4 ${theme === 'jubicom' ? "jubicomHeaders" : "dafiHeaders"} rounded-l-full float-right">A tu evento</p></div>`;
            break;
        case 'calificaciones':
            fondo = require('../../assets/img/header/calificaciones.jpg')
            texto = `<div class="toRigth-hm"> <p class="uppercase text-4xl mr-2">+ Tu avance!</p> <p class="uppercase py-2 px-4 ${theme === 'jubicom' ? "jubicomHeaders" : "dafiHeaders"} rounded-l-full float-right">Calificación</p></div>`;
            break;
        case 'certificados':
            fondo = require('../../assets/img/header/certificados.jpg')
            texto = `<div class="toRigth-hm"> <p class="uppercase text-4xl mr-2">+ Tus logros!</p> <p class="uppercase py-2 px-4 ${theme === 'jubicom' ? "jubicomHeaders" : "dafiHeaders"} rounded-l-full float-right">Certificados</p></div>`;
            break;
        case 'recompensas':
            fondo = require('../../assets/img/header/recompensas.jpg')
            texto = `<div class="toRigth-hm"> <p class="uppercase text-4xl mr-2">+ Obten tus</p> <p class="uppercase py-2 px-4 ${theme === 'jubicom' ? "jubicomHeaders" : "dafiHeaders"} rounded-l-full float-right">Recompensas</p></div>`;
            break;
        case 'reconocimientos':
            fondo = require('../../assets/img/header/reconocimientos.jpg')
            texto = `<div class="toRigth-hm"> <p class="uppercase text-4xl mr-2">+ Tus logros!</p> <p class="uppercase py-2 px-4 ${theme === 'jubicom' ? "jubicomHeaders" : "dafiHeaders"} rounded-l-full float-right">Reconocimientos</p></div>`;
            break;
        case 'videoconferencias':
            fondo = require('../../assets/img/header/conferencias.jpg')
            texto = `<div class="toRigth-hm"> <p class="uppercase text-4xl mr-2">+ Descubre mas!</p> <p class="uppercase py-2 px-4 ${theme === 'jubicom' ? "jubicomHeaders" : "dafiHeaders"} rounded-l-full float-right">VideoConferencias</p></div>`;
            break;
        default:
            fondo = require('../../assets/img/header/cursos.jpg')
            break;
    }

    let item = ranking.ranking;
    //console.log(ranking.ranking);

    return (
        <>
            {url !== 'secuencia' ?
                <header className="flex flex-col-reverse lg:flex-row relative">
                    <div className="w-full bg-gray-500 p-4 flex flex-col justify-center items-center static z-10 lg:w-1/5">
                        <div>
                            <div style={{ width: "113px", height: "113px", borderRadius: "50%", backgroundColor: '#e2e1e5' }} className="-mt-16 lg:mt-0 overflow-hidden">
                                <img className="m-0 w-full overflow-hidden" src={`${authentication.user.profileimage}`} alt="" draggable="false"/>
                            </div>
                        </div>
                        <div className="flex justify-center flex-col items-cener text-center">
                            <h2 className="mb-2 text-blue-500 font-bold text-xl">{authentication.user.firstname} {authentication.user.lastname}</h2>
                            <p className="mb-1 text-sm">Código personal: {authentication.user.numEmpleado}</p>
                            <p className="mb-1 text-sm">Fideicomiso: {authentication.user.fideicomiso}</p>
                            <p className="mb-1 text-sm">Órgano: {authentication.user.comite}</p>
                            <p className="mb-1 text-sm">Puesto: {authentication.user.puesto}</p>
                            <p className="mb-1 text-sm">Puntos disponibles: {item.rewardspoints === null ? 0: item.rewardspoints }</p>
                        </div>
                    </div>
                    <div style={{ overflow: 'none', backgroundImage: `url(${fondo.default})` }} className="flex justify-end items-center w-full lg:w-4/5 h-72 lg:h-auto bg-no-repeat bg-cover bg-top">
                        {
                            url !== 'curso' ?
                                <div className="lg:block text-white text-2xl w-5/5" dangerouslySetInnerHTML={{ __html: texto }}></div> :
                                <div className="lg:block text-white text-2xl w-3/5">
                                    <div className="toRigth-hm float-right">
                                        <p className="uppercase text-4xl">+ Curso</p>
                                        <p className={`uppercase py-2 px-4 ${theme === 'jubicom' ? "jubicomHeaders" : "dafiHeaders"} rounded-l-full`}>
                                            {
                                                curso[0] == undefined ? '' : curso[0].fullname
                                            }
                                        </p>
                                    </div>
                                </div>
                        }
                    </div>
                </header> : <></>
            }
        </>
    );
}

export default HeaderPerfil;
