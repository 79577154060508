import React from 'react';
import { useHistory } from 'react-router';
import descarga_certificado from '../../../../assets/img/descarga_certificado.svg'

const CursoTerminado = ({ curso }) => {
    const history = useHistory();

    const route = history.location.pathname;

    console.log(curso)

    const redireccionar = () => history.push('certificados');
    return (
        <div className="flex items-center justify-between my-3">
            <div className="w-7/12 lg:w-9/12 md:w-8/12 sm:w-7/12" style={{ wordWrap: "break-word" }}>
                <p className="text-sm uppercase mb-2">{curso.fullname}</p>

                <div className={route === '/inicio' || route === '/eventos' || route === '/cursos' ? 'h-2 relative w-full rounded-full overflow-hidden mb-2' : 'h-4 relative w-10/12 rounded-full overflow-hidden mb-2'}>
                    <div className="w-full h-full bg-gray-700 absolute"></div>
                    <div className="h-full bg-green_bar absolute" style={{ width: `${curso.progress}%` }}></div>
                </div>

                <div className="flex justify-between">
                    <p className="text-xs bg-blue_calificacion text-white p-0.5"> Calificacion: {curso.grade} </p>
                    <div className="flex flex-row items-center align-middle">
                        <img src={descarga_certificado} style={{ width: '10px', height: '10px' }} alt="" draggable="false"/>
                        {/*<p className="text-xs pl-1 text-gray-600 cursor-pointer" onClick={redireccionar}> Descargar certificado </p>*/}
                    </div>
                </div>
            </div>

            <div className="flex flex-col mt-1">
                { route === '/inicio' || route === '/eventos' || route === '/cursos' 
                ? <p className="text-right text-xs text-gray-800 mb-1">Avance</p> : null
                }

                <div className="w-2/12">
                    <p className={route === '/inicio' || route === '/eventos' || route === '/cursos' ? "text-blue-500 text-12" : 'text-gray-700'}>{ Number.parseFloat(curso.progress).toFixed(2) }%</p>
                </div>
            </div>



        </div>
    );
}

export default CursoTerminado;