import { cursos,curso } from '../_constants/cursos';
import { cursos_services } from '../_services/cursos';

import { alertActions } from './alert';

import Swal from 'sweetalert2';

export const cursos_actions = {
    getCursosInscritos,
    getCurso
};

function getCursosInscritos() {
    return dispatch => {
        dispatch( request() );

        cursos_services.getCursosInscritos()
            .then(
                cursos => {
					console.log('courses');
                    if(typeof(cursos.exception)!=='undefined') {
                        //localStorage.clear();
                        //window.location.reload();
                        
                        Swal.fire({title:'Error', text: cursos.exception.message, icon: 'error', confirmButtonText: 'Continuar'});
                    } else {

                    //if(cursos.length > 0 ){
                        dispatch(success(cursos));
                    //}else{
                        //dispatch(failure(cursos.error.toString()));
                        //dispatch(alertActions.error(cursos.error.toString()));
                        //localStorage.clear();
                        //window.location.reload();
                    //}
                    }
                },
                error => {
                    let lErrorMessage = error.toString();
                    dispatch(failure(lErrorMessage));
                    dispatch(alertActions.error(lErrorMessage));
                    //localStorage.clear();
                    //window.location.reload();
                    Swal.fire({title:'Error', text: lErrorMessage, icon: 'error', confirmButtonText: 'Continuar'});
                }
            ).catch(e => { dispatch(failure(e.toString())); Swal.fire({title:'Error', text: e.toString(), icon: 'error', confirmButtonText: 'Continuar'}); })
    };

    function request() { return { type: cursos.CURSOS_REQUEST , payload: true } }
    function success(cursos_params) { return { type: cursos.CURSOS_SUCCESS , payload: cursos_params } }
    function failure(error) { return { type: cursos.CURSOS_FAILURE , payload: error } }
}

function getCurso(id) {
    return dispatch => {
        dispatch( request() );

        cursos_services.getCurso(id)
            .then(
                curso => {
					//console.log(curso);
					//console.log(typeof(curso.exception));
                    if (typeof(curso.exception)!=='undefined') {
						console.log(curso.exception);
                        //localStorage.clear();
                        //window.location.reload();
                        Swal.fire({title:'Error', text: cursos.exception.message, icon: 'error', confirmButtonText: 'Continuar'});
                    } else {
						console.log('course loaded');
						console.log(curso);
                        //if(curso.length > 0 ){
                            dispatch(success(curso));
                        //}else{
                            //dispatch(failure(curso.error.toString()));
                            //dispatch(alertActions.error(curso.error.toString()));
                        //}
                    }
                },
                error => {
                    let lErrorMessage = error.toString();
                    console.log(lErrorMessage);
                    dispatch(failure(lErrorMessage));
                    dispatch(alertActions.error(lErrorMessage));
                    Swal.fire({title:'Error', text: lErrorMessage, icon: 'error', confirmButtonText: 'Continuar'});
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: curso.CURSO_REQUEST , payload: true } }
    function success(cursos_params) { return { type: curso.CURSO_SUCCESS , payload: cursos_params } }
    function failure(error) { return { type: curso.CURSO_FAILURE , payload: error } }
}
