import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recompensas_actions } from '../../store/_actions/recompensas';
import Swal from 'sweetalert2'

import './Recomensa.scss'
// Imagen: success.png, unlock.png, lock.png

const Recompensa = ({ itemRecompensas, itemCanjeados }) => {
    const dispatch = useDispatch();
    const ranking = useSelector(state => state.rankings);
    let puntosDisponibles = ranking.ranking;

    itemRecompensas.type = 'success';
    const img = require(`../../assets/img/${itemRecompensas.type}.png`)

    const handleClickExchange = async (itemRecompensas) => {
        console.log(itemRecompensas);
        if (puntosDisponibles.rewardspoints >= itemRecompensas.currentprice) {
            await recompensas_actions.canjear_articulo(itemRecompensas);
            dispatch(recompensas_actions.get_recompensas());
            dispatch(recompensas_actions.get_canjeados());
        } else {
            Swal.fire(
                {
                    icon: 'error',
                    title: 'Lo sentimos',
                    text: 'No cuentas con los puntos suficientes para canjear esta recompensa.',
                    confirmButtonColor: '#424242',
                }
            )
        }
        
    }

    const countCanjeados = itemCanjeados.filter((item) => item.articleid === itemRecompensas.id).length;

    return (
        <div style={{ maxWidth: '300px' }} className="mx-6 p-2 w-full sm:w-1/3 md:w-1/4 lg:w-1/5">
            <div className="recompensa-estatus">
                <img className="recompensa-estatus-img" src={img.default} alt="" draggable="false"/>
                <img className='mx-auto' style={{width: '75%', height: '75%'}} src={itemRecompensas.image} alt="" draggable="false"/>
            </div>
            <div className="mb-2">
                <h5 className="text-blue-500 font-black uppercase">{itemRecompensas.title}</h5>
                <p className="text-gray-800 font-bold">{itemRecompensas.currentprice} Puntos.</p>
            </div>
            <div>

                {itemRecompensas.type == 'success' ?
                    <div className="flex items-center justify-center inline">
                        <button onClick={() => { handleClickExchange(itemRecompensas) }} className="bg-blue-500 text-white text-xs p-2 px-4 rounded-xl uppercase mr-3">Adquirir</button>
                        <span className="border border-current rounded px-2 py-1">{countCanjeados}</span>
                    </div>

                    : ''}
            </div>
        </div>
    );
}

export default Recompensa;