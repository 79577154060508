const comites = (comite) => {
    switch (comite.toUpperCase()) {
        case "REPRESENTANTES DE SOCIOS":
        case "REPRESENTANTE DE SOCIO":
        case "REPRESENTANTES DE SOCIO":
            return "REPRESENTANTES DE SOCIO";

        case "ASISTENTE REGIONAL":
        case "ASISTENTES REGIONALES":
            return "ASISTENTES REGIONALES";
        case "COMITÉ TÉCNICO":
        case "COMITE TECNICO":
            return "COMITÉ TÉCNICO";

        case "PATRONATO DE OBRAS":
        case "PATRONATO":
            return "PATRONATO";
        // case "CONSEJO DE VIGILANCIA":
        //   return comite;
        // case "COMISIÓN DE AUDITORÍA":
        //   return comite;

        default:
            return comite;
    }
};

export const getAreaName = (fide, comite) => {
    console.log(fide, comite)
    if (!fide) return "";

    let aux = "";
    if (fide === "FIGLOSTASE") aux = "FST";
    else if (fide === "FIGLOSNTSA") aux = "FSN";
    else if (!aux) {
        aux = `${fide.charAt(fide.length - 2)}${fide.charAt(
            fide.length - 1
        )}`.trim();
    }


    fide = !isNaN(aux) ? fide.charAt(0) + aux : aux;
    return `${fide} - ${comites(comite)}`;
};
