import React, { useEffect } from 'react';
import Certificado from '../../Components/Certificados/Certificado';
import Tag from '../../Components/Tag';
import { useDispatch, useSelector } from 'react-redux';
import { certificados_actions } from '../../store/_actions/certificados'
import { ReactComponent as Loading } from "../../assets/loading.svg";
import { rankings_actions } from '../../store/_actions/ranking';

const Certificados = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const get_certificados = () => dispatch(certificados_actions.getCertificados());
        const get_certificados_custom = () => dispatch(certificados_actions.getCertificadosCustom());
        dispatch(rankings_actions.get_ranking());
        get_certificados();
        get_certificados_custom();
    }, []);

    const certificados = useSelector(state => state.certificados.certificados);
    const custom_certificados = useSelector(state => state.certificados.custom_certificados);
    const certificado_custom_loading = useSelector(state => state.certificados.certificado_custom_loading)
    const certificado_loading = useSelector(state => state.certificados.certificado_loading);
    const certificates = custom_certificados !== undefined ? certificados.concat(custom_certificados) : certificados;


    return (
        <>
            {
                certificado_loading === true ?
                    <div style={{ height: '50vh' }} className='flex items-center justify-center items-center'>
                        <Loading className='animate-spin h-16 w-16 inline-block text-blue-500' />
                    </div>
                    :
                    <div style={{ height: certificates.length > 5 ? "auto" : '50vh' }}>
                        {/* <Nav/> */}
                        {
                            certificado_loading === false && certificado_custom_loading === false ?
                                <div className="flex justify-center items-center md:items-center lg:items-end lg:flex-row md:justify-center lg:justify-end">
                                    <Tag imagen="certificados.png" type="CERTIFICADOS" number={certificates.length} />
                                </div>
                                : null
                        }
                        {/*<Tag imagen="certificados.png" type="CERTIFICADOS" number="10" />*/}
                        <div className="container mx-auto flex justify-center items-center lg:items-start lg:justify-start">
                            <div className="p-4 text-center lg:text-left">
                                <h3 className="text-2xl uppercase text-blue-500 font-black mb-5">
                                    Mis Certificados
                                </h3>
                            </div>
                        </div>

                        <div className="container mx-auto flex flex-wrap w-full mt-5 mb-5">
                            {
                                certificado_loading == false && certificados != undefined ?
                                    <>
                                        {certificado_loading == false && certificados != undefined ? certificados.map(item => (
                                            <Certificado key={`${item.id}${item.name}`} id={item.certificateid} texto={item.name} custom={false} />
                                        )) : 'Cargando...'}
                                        {
                                            certificado_custom_loading == false && custom_certificados != undefined ? custom_certificados.map(item => (
                                                <Certificado key={`${item.id}`} id={item.certificateid} texto={item.name} custom={true} />
                                            )) : certificado_custom_loading == false && custom_certificados == undefined ? "" : ' Cargando...'
                                        }
                                    </>
                                    :
                                    <div style={{ height: '50vh' }} className='flex items-center justify-center items-center'>
                                        <Loading className='animate-spin h-16 w-16 inline-block text-blue-500' />
                                    </div>
                            }
                        </div>
                    </div>
            }

            {/* <Footer/> */}
        </>
    );
}

export default Certificados;