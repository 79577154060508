import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api_iac from '../../config/instance_axios';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';

const Secuencia = (props) => {
    const { scoid } = props.match.params;
    const [url, setUrl] = useState('');
    const [isCompleted, setIsCompleted] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);

    let history = useHistory();
    let iteracionContador = 0; // contador de iteraciones
    let iteracionMaxima = 10; // maximo de iteraciones

    const variableDeIntervalo = setInterval(modificarIframe, 500);


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        getIframe(scoid, user.token);
        /*const interval = setInterval(() => {
            var firstFrame = document.querySelector('iframe'), secondFrame, finishButton;

            if ( (firstFrame!==null) && (firstFrame.contentDocument!==null)) {
                secondFrame = firstFrame.contentDocument.querySelector('iframe');
                if ( (secondFrame!==null) && (secondFrame.contentDocument!==null) ) {
                    finishButton = secondFrame.contentDocument.querySelector('.component.finish-button .component-widget button');
                    if ( (finishButton!==null) && (finishButton.style.display=='none') ) {
                        console.log('Finish button pressed');
                        setIsCompleted(true);
                    }
                }
            }
        }, 3000);
        return () => clearInterval(interval);*/




    }, []);

    useEffect(() => {
        if (isCompleted) {
            history.push("/");
        }
    }, [isCompleted]);

    const getIframe = async (id, token) => {
        const consulta = await api_iac.post(`webservice/rest/server.php?
            wstoken=${token}&
            wsfunction=targett_api_open_scorm&
            scormid=${id}&
            moodlewsrestformat=json`
        );
        setUrl(consulta.data.url)

        // window.open(consulta.data.url)
        return consulta.data;
    }

    const user = useSelector(state => state.authentication.user);

    function modificarIframe() {
        if (iteracionContador === iteracionMaxima) {
            clearInterval(variableDeIntervalo);
            return;
        }

        let iframeAModificar = document.querySelector('iframe');

        if (!iframeAModificar || !iframeAModificar === 'undefined') {
            iteracionContador++;
            return;
        }


        clearInterval(variableDeIntervalo);

        let doc = iframeAModificar.contentDocument || iframeAModificar.contentWindow.document;
        let estilos = `<style>

        @media only screen and (max-width: 1280px){
            #page-mod-scorm-player #scormpage #scorm_object{
                height: 100% !important;
            }
        }

        @media only screen and (max-width: 1366px){
            #page-mod-scorm-player #scormpage #scorm_object{
                height: 100% !important;
            }
        }
        
        #page-mod-scorm-player #scormpage #scorm_object{
            height: 100% !important;
        }

        </style>`;

        doc.body.innerHTML = doc.body.innerHTML + estilos;
        console.log("Se reemplazaron estilos")
    }

    function setFullScreen() {
        let iframeAModificar = document.querySelector('#iframe');

        if (!iframeAModificar || !iframeAModificar === 'undefined') {
            return;
        }

        //Obtenemos el iframe
        let iframe = document.querySelector('iframe');

        iframe.src = url;

        iframeAModificar.style.position = "fixed";
        iframeAModificar.style.top = "0";
        iframeAModificar.style.bottom = "0";
        iframeAModificar.style.right = "0";
        iframeAModificar.style.width = "100%";
        iframeAModificar.style.height = "100%";
        iframeAModificar.style.border = "none";
        iframeAModificar.style.margin = "0";
        iframeAModificar.style.padding = "0";
        iframeAModificar.style.overflow = "hidden";
        iframeAModificar.style.zIndex = "999999";


        let btn = document.querySelector('#btnCloseCourse');
        btn.style.zIndex = "9999999";
        btn.style.top = "-93px";

        let btn2 = document.querySelector('#btnFullScreenCourse');
        btn2.style.zIndex = "9999999";
        btn2.style.top = "-93px";
    }

    function removeFullScreen() {
        let iframeAModificar = document.querySelector('#iframe');

        if (!iframeAModificar || !iframeAModificar === 'undefined') {
            return;
        }

        //Obtenemos el iframe
        let iframe = document.querySelector('iframe');

        iframe.src = url;

        iframeAModificar.style.position = "";
        iframeAModificar.style.top = "";
        iframeAModificar.style.bottom = "";
        iframeAModificar.style.right = "";
        iframeAModificar.style.width = "";
        iframeAModificar.style.height = "";
        iframeAModificar.style.border = "";
        iframeAModificar.style.margin = "";
        iframeAModificar.style.padding = "";
        iframeAModificar.style.overflow = "";
        iframeAModificar.style.zIndex = "";

        let btn = document.querySelector('#btnCloseCourse');
        btn.style.zIndex = "1";
        btn.style.top = "0";

        let btn2 = document.querySelector('#btnFullScreenCourse');
        btn2.style.zIndex = "1";
        btn2.style.top = "0";
    }

    function changeIconFullScreen() {
        let btn = document.querySelector('#iconFullScreen');
        if (btn.classList.contains('fa-expand')) {
            btn.classList.remove('fa-expand');
            btn.classList.add('fa-compress');
        } else {
            btn.classList.remove('fa-compress');
            btn.classList.add('fa-expand');
        }
    }


    return (
        <div className="bg-white absolute inset-x-0 top-24 bottom-0" style={{ marginTop: "-2px", marginBottom: "-4px" }}>
            {/* Boton de regreso */}
            <div className="flex absolute justify-end top-0 h-14 w-14" id='btnCloseCourse'>
                <button className="bg-gray-400 hover:bg-gray-700 text-white h-full w-full font-bold py-2 px-4 rounded" onClick={
                    () => {
                        Swal.fire({
                            title: '¿Estás seguro de cerrar el curso?',
                            text: "No podrás revertir esta acción",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Si, estoy seguro',
                            customClass: 'swal-firemodify'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                history.push("/");
                            }
                        })
                    }
                }>
                    X
                </button>
            </div>

            {/* Boton de fullscreen */}

            <div className="flex absolute justify-end top-0 left-14 h-14 w-14" id='btnFullScreenCourse'>
                <button className="bg-gray-400 hover:bg-gray-700 text-white h-full w-full font-bold py-2 px-4 rounded" onClick={
                    (e) => {
                        e.preventDefault();
                        if (!isFullScreen) {
                            setFullScreen();
                            setIsFullScreen(true);
                            changeIconFullScreen();
                        } else {
                            removeFullScreen();
                            setIsFullScreen(false);
                            changeIconFullScreen();
                        }
                    }
                }>
                    <i id='iconFullScreen' className="fas fa-expand"></i>
                </button>
            </div>

            <div id='iframe' className={`w-full h-full`}>
                <iframe className={`w-full h-full`} src={url} frameBorder="0" title="secuencia" />
            </div>

        </div>
    );
}

export default Secuencia;
