import React, { useState } from 'react';
import FormAuth from '../../Components/FormAuth';

// Enrutamiento
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { userAuthActions } from "../../store/_actions/user_auth";



const Login = () => {
    const dispatch = useDispatch();

    const cargadando = useSelector(state => state.loading );
    const error = useSelector(state => state.authentication.error );

    // UseState
    const [user, setUser] = useState({
        username: '',
        password: '',
    })

    const handleChange = (e) => {
        setUser({ 
            ...user,
            [e.target.name]: e.target.value 
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(userAuthActions.login(user.username, user.password));

    }
    
    return ( 
        <>
            <FormAuth>
                <form onSubmit={handleSubmit}>
                    { error != undefined ? <p className="p-2 mb-2 block bg-red-500 text-white text-center rounded">{error}</p> : null }
                    <div className="flex flex-col items-center justify-center w-full mb-4">
                        <label className="text-md text-gray-600" htmlFor="username">Correo</label>
                        <input onChange={handleChange} className="flex w-full bg-gray-200 rounded p-1" type="text" name="username" id="username"/>
                    </div>
                    <div className="flex flex-col items-center justify-center w-full mb-8">
                        <label className="text-md text-gray-600" htmlFor="correo">Contraseña</label>
                        <input onChange={handleChange} className="flex w-full bg-gray-200 rounded p-1" type="password" name="password" id="password"/>
                    </div>
                    <div className="flex flex-col items-center justify-center w-full">
                        <button className="py-3 bg-blue-500 text-white rounded flex justify-center w-4/5 font-bold text-xl hover:bg-blue-400">Entrar</button>
                    </div>
                </form>
            </FormAuth>
        </>
    );
}


export default Login;