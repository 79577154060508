import React from 'react';
import Cursos from './Components/Cursos';
import Reconocimientos from './Components/Reconocimientos';



const Aside = () => {
    return ( 
        <aside className="flex flex-col w-full md:w-1/4 lg:w-1/5 bg-gray-500 p-3">
            <Reconocimientos/>
            <Cursos/>
        </aside>
    );
}
 
export default Aside;