import { cursos,curso } from '../_constants/cursos';


const initialState = {
  cursos: [],
  curso: []
};

export function cursos_reducers(state = initialState, action) {
  switch (action.type) {
    case cursos.CURSOS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case cursos.CURSOS_SUCCESS:
      return {
        ...state,
        loading: false,
        cursos: action.payload
      };
    case cursos.CURSOS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case curso.CURSO_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case curso.CURSO_SUCCESS:
      return {
        ...state,
        loading: false,
        curso: action.payload
      };
    case curso.CURSO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state
  }
}