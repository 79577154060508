import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { events_actions } from '../../store/_actions/calendar';
// import DayPicker from 'react-day-picker';
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/es";
import { formatEvents, messages } from "../../helpers/events";
import Event from "./Event";
import EventDetail from "./EventDetail";
// import 'react-day-picker/lib/style.css';





moment.locale("es");
const localizer = momentLocalizer(moment);

const formats = {
    weekdayFormat: (date, culture, localizer) =>
        localizer.format(date, "dddd", culture),
};

const Calendario = ({ canShow = false }) => {
    //const dispatch = useDispatch();
    const arrayEvents = useSelector((state) => state.calendar.events);
    const arrayMeetings = useSelector((state) => state.meetings.meetingsAll);

    // const [seletedDate, setSeletedDate] = useState(new Date());
    // const [ArrayMeetingSelected, setArrayMeetingSelected] = useState([]);

    const [lastView, setLastView] = useState(
        localStorage.getItem("lastView") || "month"
    );
    const [currentEvent, setCurrentEvent] = useState(null);
    const events = useMemo(
        () => formatEvents(arrayMeetings, arrayEvents),
        [arrayEvents, arrayMeetings]
    );

    // const conferencias = [];
    // arrayMeetings.map((meetings) => {
    //     conferencias.push(new Date(meetings.start_time.substring(0, 19)));
    // });

    // const handleDayClick = (day) => {
    //     let formatDaySelected = moment(day).format('YYYY-MM-DD');

    //     //const arrayMeetingDay = [];
    //     // arrayMeetings.map((meetings) => {
    //     //     if (formatDaySelected === meetings.start_time.substring(0, 10)) {
    //     //         arrayMeetingDay.push(meetings);
    //     //     }
    //     // });

    //     const arrayMeetingDay = arrayEvents.filter((event) => formatDaySelected === event.start.substring(0, 10) )

    //     setArrayMeetingSelected(arrayMeetingDay);
    //     setSeletedDate(day);
    // }

    const eventStyleGetter = (event, start, end, isSelected) => {
        const style = {
            backgroundColor: event.bgcolor,
            borderRadius: "0px",
            opacity: 0.8,
            display: "block",
            color: "white",
        };
        return {
            style,
        };
    };

    const onViewChange = (e) => {
        setLastView(e);
        localStorage.setItem("lastView", e);
    };
    //console.log(arrayEvents, 'array events')

    return (
        <>
            <div>
                <div className="mb-3">
                    <h4 className="text-blue-500 text-4xl">Calendario</h4>{" "}
                </div>
                <div className="flex md:flex-col sm:flex-col lg:flex-row lg:justify-between sm:justify-center bg-white">
                    {/* <DayPicker
                        showOutsideDays
                        showWeekNumbers
                        todayButton="Ir al dia de hoy"
                        months={MONTHS['es']}
                        weekdaysLong={WEEKDAYS_LONG['es']}
                        weekdaysShort={WEEKDAYS_SHORT['es']}
                        firstDayOfWeek={FIRST_DAY_OF_WEEK['es']}
                        labels={LABELS['es']}
                        initialMonth={new Date()}
                        selectedDays={conferencias}
                        onDayClick={handleDayClick}
                    /> */}
                    <Calendar
                        showMultiDayTimes
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        messages={messages}
                        /* tanto onSelectSlot como slectable de necesita para Detecta si se da click fuera del evento y lo desactiva poniendo el activeEvent =null para ocultar el botón de delete */
                        //onSelectSlot={clickMenuAdd}
                        //onSelectEvent={onDoubleClick}
                        /* Obtiene el estilo definido para cambiar el aspecto del calendar */
                        eventPropGetter={eventStyleGetter}
                        onSelectEvent={(event) => canShow && setCurrentEvent(event)}
                        //onDoubleClickEvent={onDoubleClick}
                        onView={onViewChange}
                        selectable={true}
                        /* Se especifica que la vista que va tener cuando ingresemos a la página es el lastView */
                        view={lastView}
                        formats={formats}
                        components={{
                            /* Recive CalendarEvent.js como referencia */
                            /* En CalendarEvent se especifica lo que va a mostrar el recuadro de la tarea que se ubica en los diferentes días */
                            event: Event,
                        }}
                        style={{
                            height: "100vh",
                            marginTop: "20px",
                            marginBottom: "50px",
                            flexGrow: 1,
                        }}
                    />
                    {currentEvent && (
                        <EventDetail
                            event={currentEvent}
                            handleClose={() => setCurrentEvent(null)}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Calendario;

/**
 * <div className="">
                        <div className="p-2 flex justify-evenly items-center">
                            <div className="text-md mr-2 w-5/12 text-center">
                                {/* <p className="text-gray-700 text-xs">Eventos del dia</p> */
/* <p className="text-gray-800 text-sm">{formatDateActual(seletedDate)}</p> */
// </div>
// <div className="bg-gray-700 text-white p-1 rounded">
//     <ul>
//         {
// ArrayMeetingSelected.length > 0 ?
//     ArrayMeetingSelected.map(item =>
//         <li className="mb-2">
//             <span>{formatDate(item.start_time)}</span>
//             {item.topic}
//             <a className="hover:bg-blue-900 p-1" href={item.join_url} target="_blank" rel="noreferrer">Link</a>
//         </li>
//     ) :
//     <p>No tienes evento en este dia</p>
// ArrayMeetingSelected.length > 0 ? (
//     ArrayMeetingSelected.map(item =>
//         <li className="mb-2">
//             <span>{formatDate(item.start)}</span>
//             {item.title}
//         </li>
//     )
// ) : <p>No tienes evento en este dia</p>
//             }
//         </ul>
//     </div>
// </div>

// <div className="p-2 flex justify-evenly items-center">
//     <div className="m-1 text-xs">
//         <p className="m-1 text-gray-700 text-xs">Eventos del dia</p>
//         <p className="m-1 text-gray-800 text-sm">20 de enero</p>
//     </div>
//     <div className="bg-gray-700 text-white p-1 rounded">
//         <ul>
//             <li>15 10:00 Conferencias</li>
//             <li>15 10:00 Conferencias</li>
//             <li>15 10:00 Conferencias</li>
//         </ul>
//     </div>
// </div>
// </div>
