import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VideoConferencia from '../../Components/VideoConferencias/VideoConferencia';
import { meetings_actions } from '../../store/_actions/conferencias';
import Tag from '../../Components/Tag';
import { ReactComponent as Loading } from "../../assets/loading.svg";
import { rankings_actions } from '../../store/_actions/ranking';


const VideoConferencias = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const meetings_all = () => dispatch(meetings_actions.get_meetings());
        dispatch(rankings_actions.get_ranking());
        meetings_all();
    }, []);

    const meetings = useSelector(state => state.meetings);


    return (
        <div style={{ height: meetings.meetingsAll.length > 0 ? 'auto' : '50vh' }}>
            {meetings.meetings_loading === true ?
                <div style={{ height: '50vh' }} className='flex justify-center items-center flex-col flex-wrap w-full h-full'>
                    <Loading className='animate-spin h-16 w-16 inline-block text-blue-500' />
                </div>
                :

                <>
                    <div className="flex justify-center items-center md:items-center lg:items-end lg:flex-row md:justify-center lg:justify-end">
                        <Tag imagen="reconocimientos.png" type="VIDEOCONFERENCIAS" number={meetings.meetingsAll.length} />
                    </div>

                    <div className="container mx-auto flex justify-center items-center lg:items-start lg:justify-start">
                        <div className="p-4 text-center lg:text-left">
                            <h3 className="text-2xl uppercase text-blue-500 font-black mb-5">
                                Mis videoconferencias
                            </h3>
                        </div>
                    </div>

                    <div className="container mx-auto flex flex-wrap w-full items-baseline text-center mt-5 mb-5">
                        {
                            meetings.meetingsAll.length > 0 ?
                                meetings.meetingsAll.map(item =>
                                    <VideoConferencia
                                        key={item.id}
                                        idMeeting={item.id}
                                        url_video={item.join_url}
                                        texto={item.topic} fecha={item.start_time} />)
                                : 'Sin Video Conferencias programadas'
                        }
                    </div>
                </>
            }
        </div>
    );
}

export default VideoConferencias;